import FullCalendar from '@fullcalendar/react'
import timegrid from '@fullcalendar/timegrid'
import dayGrid from '@fullcalendar/daygrid'
import { useTranslation } from 'react-i18next';
import interactable from '@fullcalendar/interaction'
import { useState } from 'react';
import { LinearProgress } from '@mui/material';
import { FadeLoader } from 'react-spinners';
import { ConfirmationDialog } from '../../components/general/ConfirmationDialog';
import { AvailabilityDialog } from './AvailabilityDialog';
import {useApi} from '../../hooks/useApi'
import useQuery from '../../hooks/useQuery';
import tracker from '../../api/tracker';




export default function AvailabilityCalendar() {

  const {t} = useTranslation()
  const [selectedWindow, setSelectedWindow] = useState()
  const [selectedAvailability, setSelectedAvailability] = useState()
  const [seletectedRequest, setSelectedRequest] = useState()
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const [calendarStart, setCalendarStart] = useState()
  const [calendarEnd, setCalendarEnd] = useState()
  const [availabilityLoading, setAvailabilityLoading] = useState(true)
  const {doSave, doDelete} = useApi()

  const [showWindowDialog, setShowWindowDialog] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showResizeDialog, setShowResizeDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const handleSelect = (info) => {
    setShowConfirmationDialog(true)
    setSelectedWindow(info)
  }
  const handleResize = (info) => {
    setShowResizeDialog(true)
    setSelectedWindow(info)
  }
  const refreshTable = async (start, end) => {
    if(!start){
      start = calendarStart
      end = calendarEnd
    }
    else{
      setCalendarStart(start)
      setCalendarEnd(end)
    }
    setAvailabilityLoading(true);

    let response = await tracker.get(`availability?date_filter=start,${start},${end}`);
    const windows = response.data.data
    const events = windows.map((window) => {
      return {
        start: window.start,
        end: window.end,
        title: t('available'),
        hashid: window.hashid,
        type: 'window'
      }
    })
    setEvents(events)

    response = await tracker.get(`meetings?date_filter=start,${start},${end}&filter=forCalendar`);
    const requests = response.data.data.map((request) => {
      let backgroundColor
      if(request.status === 'accepted') {
        backgroundColor = 'green'
      }
      else if (request.status === 'cancelled'){
        backgroundColor = 'darkred'
      }
      else {
        backgroundColor = 'gray'
      }

      return {
        start: request.start,
        end: request.end,
        backgroundColor,
        status: request.status,
        editable: false,
        hashid: request.hashid,
        requestorName: `${request.name} ${request.lastname}`,
        phone: request.phone,
        motive: request.motive,
        type: 'pending_request',
        title: `${request.name}`
      }
    })
    const moreEvents = [].concat(events, requests)
    setEvents(moreEvents)
    setAvailabilityLoading(false);
  };

  const submit = async () => {
    const data = {
      start: selectedWindow.startStr,
      end: selectedWindow.endStr,
    }
    setLoading(true)

    const response = await doSave('availability', data, t('availability_success'))
    setLoading(false)
    setShowConfirmationDialog(false)
    refreshTable()
  }

  const update = async () => {
    const data = {
      start: selectedWindow.event.startStr,
      end: selectedWindow.event.endStr,
    }
    setLoading(true)

    const response = await doSave(
      `availability/${selectedWindow.event.extendedProps.hashid}/update`,
      data,
      t('availability_success'))
    setLoading(false)
    setShowResizeDialog(false)
    refreshTable()
  }

  const resolve = async (status, time , timeEnd) => {
    const data = {
      start: time,
      end: timeEnd,
      status
    }
    setLoading(true)

    const response =  await tracker.post(`meetings/${seletectedRequest.extendedProps.hashid}/resolve`, data);
    setLoading(false)
    setShowWindowDialog(false)
    refreshTable()
  }

  const onDelete = async() => {
    setLoading(true)
    await doDelete(`availability/${selectedAvailability.extendedProps.hashid}`, t('availability_delete'), 'woops')
    setLoading(false)
    setShowDeleteDialog(false)
    refreshTable()

  }

  const onEventClick = (info) => {
    const type = info.event.extendedProps.type
    if(type === 'window'){
      setShowDeleteDialog(true)
      setSelectedAvailability(info.event)
    }
    else {
      setSelectedRequest(info.event)
      setShowWindowDialog(true);

    }
  }
  return (
    <>
      {availabilityLoading && (
        <LinearProgress  />
      )}

      <FullCalendar
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        }}
        allDaySlot={false}
        editable
        eventClick={onEventClick}
        select={handleSelect}
        eventResize={handleResize}
        eventStartEditable={false}
        selectable
        events={events}
        slotMinTime={'09:00:00'}
        slotMaxTime={'18:00:00'}
        datesSet={(dateInfo) => {
          refreshTable(dateInfo.startStr, dateInfo.endStr)
        }}
        plugins={[ timegrid , dayGrid, interactable]}
        initialView="timeGridWeek"
      />
      <AvailabilityDialog
        open={showWindowDialog}
        request={seletectedRequest}
        onSubmit={resolve}
        onClose={() => {
          setShowWindowDialog(false);
        }}
        loading={loading}
        message={t('delete_confirm')}
      />
      <ConfirmationDialog
        open={showConfirmationDialog}
        onClose={() => {
          setShowConfirmationDialog(false);
        }}
        loading={loading}
        onConfirm={submit}
        message={t('window_confirm')}
      />
      <ConfirmationDialog
        open={showResizeDialog}
        onClose={() => {
          setShowResizeDialog(false);
        }}
        loading={loading}
        onConfirm={update}
        message={t('window_resize')}
      />
      <ConfirmationDialog
        open={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        forDelete
        loading={loading}
        onConfirm={onDelete}
        message={t('window_delete')}
      />
    </>


  )
}