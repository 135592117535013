import { React, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Alert, Stack, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import FreeSelect from '../../components/hook-form/FreeSelect';
import Label from '../../components/Label';




export function AvailabilityDialog(props) {
  const { onClose,onSubmit, message, open, loading, request, ...other } = props;

  const {t} = useTranslation();
  const [status, setStatus] = useState('pending')
  const [time, setTime] = useState(null)
  const [timeEnd, setTimeEnd] = useState(null)
  const handleStatusChange = (event) => {
    // 👇 Get input value from "event"
    setStatus(event.target.value);
  };

  useEffect(() => {
    if(request){
      setStatus(request.extendedProps.status)
      setTime(dayjs(request.startStr))
      if(request.endStr){
        setTimeEnd(dayjs(request.endStr))
      }
      else {
        setTimeEnd(dayjs(request.startStr).add(1, 'hour'))
      }
    }
  }, [request]);

  const handleTimeChange = (time) => {
    console.log(time.toDate())
    setTime(time.toDate())
  };
  const handleTimeEndChange = (time) => {
    setTimeEnd(time.toDate())
  };


  const handleCancel = () => {
    onClose();
  };
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      
      <DialogTitle>{t('resolve_meeting')}</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <FreeSelect
            initialValue={status}
            value={status}
            onChange={handleStatusChange}
            options={[
              { value: 'pending', label: t('pending') },
              { value: 'accepted', label: t('accepted') },
              { value: 'rejected', label: t('rejected') },
              { value: 'cancelled', label: t('cancelled') },
            ]}
          />
          {status === 'accepted' && (
            <>
              <Label>{t('time_start')}</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker  onChange={handleTimeChange} defaultValue={dayjs(time)} />
              </LocalizationProvider>
              <Label>{t('time_end')}</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker  onChange={handleTimeEndChange} defaultValue={dayjs(timeEnd)} />
              </LocalizationProvider>
            </>
          )}

          <Typography sx={{fontWeight: 'bold'}}>
            {t('name')}:
          </Typography>
          <Typography>
            {request && request.extendedProps.requestorName}
          </Typography>
          <Typography sx={{fontWeight: 'bold'}}>
            {t('motive')}:
          </Typography>
          <Typography>
            {request && request.extendedProps.motive}
          </Typography>
          <Typography sx={{fontWeight: 'bold'}}>
            {t('phone')}:
          </Typography>
          <Typography>
            {request && request.extendedProps.phone}
          </Typography>

        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {t('cancel')}
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={() => {onSubmit(status, time, timeEnd)}}>{t('save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}