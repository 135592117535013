import axios from 'axios';

const instance = axios.create({
    baseURL: "https://holyspirit-api.nassoftwarecorp.com/api/"
   //  baseURL: 'http://localhost:8081/api/',
});

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // config.headers['Content-Type'] = 'multipart/form-data'
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
export default instance;
