import { useSnackbar } from 'notistack';
import IconButton from "@mui/material/IconButton";

import React, {Fragment, useEffect, useState} from "react";
import Iconify from '../components/Iconify'


const useNotification = () => {
    const [conf, setConf] = useState({});
   
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const action = key => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
                <Iconify icon={"mdi:close"} width={22} height={22} />
            </IconButton>
    
    );
    useEffect(()=>{
        if(conf?.msg){
            let variant = 'success';
            if(conf.variant){
                variant = conf.variant;
            }
            enqueueSnackbar(conf.msg, {
                variant,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                autoHideDuration: 5000,
                action
            });
        }
    },[conf]);
    return [setConf];
};

export default useNotification;