import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import JSZip from 'jszip';
import { PDFDocument } from 'pdf-lib';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui

import { Stack, Link, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css';

// components
import ErrorModal from '../../../components/ErrorModal';
import Label from '../../../components/Label';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from '../../../components/hook-form/RHFSelect';
import { useApi } from '../../../hooks/useApi';
import tracker from '../../../api/tracker';
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import { PinDialog } from '../../../components/general/PinDialog';
import ProductImagesUploader from './ProductImageUploader';

// ----------------------------------------------------------------------

export default function BulletinForm({ bulletin }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { doSave } = useApi();
  const [roles, setRoles] = useState([]);
  const [showPinDialog, setShowPinDialog] = useState(false);
  const [pinDialogMessage, setPinDialogMessage] = useState(false);
  const defaultValue = new Date('2022-04-17T15:30');
  const [datePickerEl, setDatePickerAnchorElement] = useState(null);
  const [massDate, setMassDate] = useState(null);
  const [productPhotos, setProductPhotos] = useState([]);
  const [zipBlob, setZipBlob] = useState(null);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOpenModal = () => {
    setOpen(true);
    setErrorMessage(t('Please_image_before_submitting'));
  };

  const handleCloseModal = () => {
    setOpen(false);
    setErrorMessage('');
  };

  const fetchRoles = async () => {
    const result = await tracker.get('roles');
    setRoles(result.data.data);
  };

  useEffect(() => {
    if (bulletin) {
      const fields = ['title', 'description'];
      fields.forEach((field) => setValue(field, bulletin[field]));
    }
    fetchRoles();
    // console.log(bulletin);
  }, [bulletin]);

  const ModelSchema = Yup.object().shape({
    title: Yup.string().required(t('Required')),
  });

  const defaultValues = {
    title: '',
  };

  const methods = useForm({
    resolver: yupResolver(ModelSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
  } = methods;

  const onSubmit = async (data) => {
    console.log(data.image);

    if (data.image) {
      const pdfFile = data.image[0];
      const zip = new JSZip();
      zip.file(pdfFile.name, pdfFile);

      const zipBlob = await zip.generateAsync({ type: 'blob' });
      setZipBlob(zipBlob);

      console.log(`Original file size: ${(pdfFile.size / 1024 / 1024).toFixed(2)} MB`);
      // console.log(`Compressed file size: ${(compressedFile.size / 1024 / 1024).toFixed(2)} MB`);

      console.log(`Tamaño del archivo ZIP: ${(zipBlob.size / 1024).toFixed(2)} KB`);
      setValue('image', zipBlob);
    } else {
      data.image = null;
    }

    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await doSave('bulletins', data, t('bulletins_saved'), '', bulletin, headers);
    navigate(`/dashboard/bulletin/`);
    if (response.status === 423) {
      setPinDialogMessage(response.response.data.message);
      setShowPinDialog(true);
    }
  };

  const handleDateTimeChange = (newValue) => {
    setMassDate(newValue);
    // setValue('date_start', sd);
    console.log(massDate);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1} direction={'column'}>
          <RHFTextField name="title" label={t('title')} />

          <RHFTextField name="description" label={t('description')} />
          <div style={{ marginTop: '80px' }}>
            <Label>{t('image')}</Label>
            <RHFFileInput name="image" />
          </div>
        </Stack>
        <input type="hidden" name="id" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/dashboard/bulletin">
          {t('go_back')}
        </Link>
      </Stack>
    </FormProvider>
  );
}
