
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
// material
import {
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
// mock
import UserForm from "../../sections/@dashboard/user/UserForm";



// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function SaveUser() {

  const [loading, setLoading] = useState(true)

  const { t } = useTranslation();



  const fetchUserData = async (filter, page) =>{

  }

  useEffect(() => {




  }, [])



  return (
    <Page title={t('user_form')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('user_form')}
          </Typography>
        </Stack>
        <Card style={{padding: "50px"}}>
         <UserForm />
        </Card>
      </Container>
    </Page>
  );
}
