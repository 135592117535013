import React from 'react';
import { useTranslation } from 'react-i18next';
import ChildrenDetails from './ChildrenDetails'; // Importa el componente ChildrenDetails

const InterestDetailsView = ({ interests }) => {
  const { t } = useTranslation();

  if (!interests || !Array.isArray(interests)) {
    return <div>{t('No_interest_data_available')}</div>;
  }

  return (
    <div style={{padding: '10px'}}>
      <h3>{t('User_Interests')}</h3>
      <ul>
        {interests.map((interest, index) => (
          <li key={index}>{t(interest)}</li>
        ))}
      </ul>
    </div>
  );
};

export default InterestDetailsView;
