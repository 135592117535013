import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

const CheckboxField = ({ name, label }) => {
  const { control } = useFormContext();

  // Generar un ID único para el input asociado al label
  const inputId = `checkbox-${name}`;

  return (
    <div>
      <label htmlFor={inputId}>
        <Controller
          name={name}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <input
              id={inputId}
              type="checkbox"
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
        {label}
      </label>
    </div>
  );
};

export default CheckboxField;
