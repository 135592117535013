

// @mui
import { FormControl, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import Icofont from 'react-icofont';

// ----------------------------------------------------------------------


export default function PlainSelect({ name, hasIcons, options, value, error, ...other }) {
  return (
    
  
        <FormControl>
            <InputLabel id="demo-simple-select-label">{other.label}</InputLabel>
            <Select
            fullWidth
            error={!!error}
            value={value || null}
            helpertext={error?.message}
            {...other}
            labelId='demo-simple-select-label'
            >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                {hasIcons && <Icofont icon={option.value} size={2} sx={{ ml: 20 }} />}&nbsp;&nbsp;&nbsp;&nbsp;
                {option.label}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
  );
}
