import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

FreeTextField.propTypes = {
  name: PropTypes.string,
};

export default function FreeTextField({ name, ...other }) {

  return (
    
        <TextField
         
          fullWidth
          {...other}
        />
  );
}
