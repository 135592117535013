import React from 'react';

import { useTranslation } from 'react-i18next';

const FamilyMembers = ({ familyMembers }) => {
  const { t } = useTranslation();

  if (!familyMembers || !Array.isArray(familyMembers)) {
    return <div>{t('No_familyMembers_data_available')}</div>;
  }

  return (
    <div  style={{padding: '10px'}}>
      <h3>{t('Family_Members')}</h3>
      <ul>
        {familyMembers.map((member, index) => (
          <li key={index}>
            <strong>{t('Name')}:</strong> {member.name}, <strong>{t('Relation')}:</strong> {member.relation}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FamilyMembers;
