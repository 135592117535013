import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Link,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from '@mui/material';
// components
import { ConfirmationDialog } from '../../components/general/ConfirmationDialog';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from '../../hooks/useQuery';
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from '../../hooks/useApi';
import tracker from '../../api/tracker';
import FreeSelect from '../../components/hook-form/FreeSelect';
import SortDropdown from '../../components/SortDropdown';
import useNotification from '../../hooks/useNotificaction';
import { PinDialog } from '../../components/general/PinDialog';

const FileDownload = require('js-file-download');

const override = {
  margin: '0 auto',
};

export default function MeetingIndex() {
  const [meetings, setMeetings] = useState([]);
  const [sendNotification] = useNotification();
  const [selectedMeeting, setSelectedMeeting] = useState();
  const [pagination, setPagination] = useState({ total: 0, per_page: 0, current_page: 1, last_page: 1 });
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [filterScope, setFilterScope] = useState('all');
  const [showShipDialog, setShowShipDialog] = useState(false);
  const [sort, setSort] = useState('desc');
  const [sortField, setSortField] = useState('created_at');
  const [selected, setSelected] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { doDelete } = useApi();
  const [loading, setLoading] = useState(true);
  const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

  const fetchData = useQuery();
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'name', label: t('name'), alignRight: false },
    { id: 'date', label: t('date'), sortable: false, alignRight: false },
    { id: 'email', label: t('email'), sortable: false, alignRight: false },
    { id: 'phone', label: t('phone'), alignRight: false },
    { id: 'status', label: t('status'), alignRight: false },
    { id: 'motive', label: t('motive'), sortable: false, alignRight: false },
    { id: 'action', label: t('edit_status'), sortable: false, alignRight: false },
    { id: 'action', label: '', sortable: false, alignRight: false },
  ];

  const filterOptions = [
    { value: 'all', label: t('all') },
    { value: 'forStatus(pending)', label: t('pending') },
    { value: 'forStatus(accepted)', label: t('accepted') },
    { value: 'forStatus(rejected)', label: t('rejected') },
  ];

  const refreshTable = async () => {
    setIsLoading(true);
    await fetchData('meetings', filter, sort, setMeetings, sortField, setPagination, page, setNotFound, filterScope);
    setIsLoading(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(refreshTable, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [filter, sort, page, sortField, filterScope]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = meetings.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilter(event.target.value);
  };
  const getStatusColor = (status) => {
    const style = {
      paddingLeft: '10px',
      fontWeight: 'bold',
      paddingBottom: '1px',
      paddingRight: '10px',
      paddingTop: '1px',
      borderRadius: '30px',
    };
    if (status === 'rejected') {
      style.color = '#FF003F';
      style.backgroundColor = '#F8E3E1';
    } else if (status === 'pending') {
      style.color = '#6d8ee9';
      style.backgroundColor = '#e0e9fa';
    } else if (status === 'accepted') {
      style.color = '#6ca181';
      style.backgroundColor = '#dff8e7';
    } else if (status === 'placed') {
      style.color = '#d1b573';
      style.backgroundColor = '#fcf9ce';
    }

    return style;
  };
  const onStatusChange = async (status, meetingHash) => {
    const response = await tracker.post(`meetings/${meetingHash}/setStatus`, {
      status,
      method: '_PUT',
    });
    sendNotification({ msg: t('status_updated'), variant: 'success' });
    refreshTable();
  };
  const onShip = async (tracking) => {
    const response = await tracker.post(`meetings/${selectedMeeting.hashid}/setStatus`, {
      tracking_numbers: tracking.split(','),
      status: 'accepted',
      method: '_PUT',
    });
    sendNotification({ msg: t('status_updated'), variant: 'success' });
    setShowShipDialog(false);
    refreshTable();
  };

  const downloadReceipts = async (meetingHash, meetingNumber) => {
    const url = `meetings/${meetingHash}/download`;
    const headers = {
      responseType: 'blob',
    };
    setIsDownloading(true);
    const response = await tracker.get(url, headers);
    setIsDownloading(false);
    setSelectedMeeting(null);

    FileDownload(response.data, `invoice_${meetingNumber}.pdf`);
  };

  return (
    <Page title={t('meetings')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('meetings')}
          </Typography>
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
            <ListToolbar
              numSelected={selected.length}
              filterName={filter}
              onFilterName={handleFilterByName}
              placeholder={`search_meetings`}
            />
            <SortDropdown sx={{ width: '200px' }} options={filterOptions} sort={filterScope} setSort={setFilterScope} />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {isLoading ? (
                  <FadeLoader color={'blue'} loading={loading} cssOverride={override} size={50} />
                ) : (
                  <>
                    <ListHead
                      headLabel={TABLE_HEAD}
                      rowCount={meetings.length}
                      omitCheckbox
                      order={sort}
                      orderBy={sortField}
                      numSelected={selected.length}
                      onRequestSort={setSortField}
                      setSort={setSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {meetings.map((row) => {
                        const { fullname, status, email, date, phone, hashid, motive } = row;

                        const statusCapitalized = status.charAt(0).toUpperCase() + status.slice(1);
                        console.log(fullname);
                        const isItemSelected = selected.indexOf(hashid) !== -1;
                        const shouldLoading = selectedMeeting && selectedMeeting.hashid === hashid && isDownloading;
                        return (
                          <TableRow
                            hover
                            key={hashid}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left">{fullname}</TableCell>
                            <TableCell align="left">{date}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{phone}</TableCell>
                            <TableCell align="left">
                              <span style={getStatusColor(status)}>{t(status)}</span>
                            </TableCell>
                            <TableCell align="left">{motive}</TableCell>
                            <TableCell align="left">
                              <FreeSelect
                                initialValue={status}
                                onChange={(e) => onStatusChange(e.target.value, hashid)}
                                options={[
                                  { value: 'accepted', label: t('accepted') },
                                  { value: 'pending', label: t('pending') },
                                  { value: 'rejected', label: t('rejected') },
                                ]}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    {notFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filter} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={pagination.total}
            rowsPerPage={pagination.per_page}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <PinDialog
            open={showShipDialog}
            onClose={() => {
              setShowShipDialog(false);
            }}
            free
            onSubmit={onShip}
            // loading={isSubmitting}
            message={
              'Add tracking numbers separated by commas (,). This will send an email notifying the user who placed the order'
            }
          />
          <ConfirmationDialog
            open={showDeleteDialog}
            onClose={() => {
              setShowDeleteDialog(false);
            }}
            /* onConfirm={handleDelete} */
            message={t('delete_confirm')}
          />
        </Card>
      </Container>
    </Page>
  );
}
