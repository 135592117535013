import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import tracker from "../../../api/tracker";



// ----------------------------------------------------------------------

export default function RequestPasswordForm() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const RequestResetSchema = Yup.object().shape({
    email: Yup.string().email(t("Enter_a_valid_email")).required(t("Email_is_required")),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(RequestResetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
      setSuccessMessage(null)
      setErrorMessage(null)
      setIsLoading(true)
      const url = `request_reset`;
      const result = await tracker.post(url, data)
        .catch( (err) => {
            if(err.response.status === 422)
              setErrorMessage(err.response.data.errors.email)
            else
              setErrorMessage(t("something_went_wrong"))  
        })
      if(result.status === 200) {
        setSuccessMessage(t("password_email_sent"))
      }
        
      setIsLoading(false)
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField className="mb-5" name="email" label={t("Correo_Electronico")} />
        {errorMessage && (
          <Alert severity="error"> {t(errorMessage)}</Alert>
        )}
        {successMessage && (
          <Alert severity="success"> {t(successMessage)}</Alert>
        )}
      </Stack>

    
      

    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        {t('Send_Email_Reset_Link')}
      </LoadingButton>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/login">
          {t('back_to_login')}
        </Link>
      </Stack>  
    </FormProvider>
  );
}
