import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import Icofont from 'react-icofont';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  hasIcons: PropTypes.bool,
  defaultValue: PropTypes.any,
};

export default function RHFSelect({ name, hasIcons, options, defaultValue, ...other }) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <InputLabel id="demo-simple-select-label">{other.label}</InputLabel>
          <Select
            {...field}
            fullWidth
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            error={!!error}
            helpertext={error?.message}
            {...other}
            labelId="demo-simple-select-label"
            // onChange={(e) => {
            //   setSelectedValue(e.target.value);
            // }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {hasIcons && <Icofont icon={option.value} size={2} sx={{ ml: 20 }} />}&nbsp;&nbsp;&nbsp;&nbsp;
                {t(option.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
