import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent, Icon } from '@mui/material';
import Icofont from 'react-icofont';

// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
//
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

// ----------------------------------------------------------------------

ResourceCard.propTypes = {
  resource: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
};

ResourceCard.defaultValues = {
  isPreview: false,
};

export default function ResourceCard({ resource, index, isPreview }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hashid, name, icon, url, created_at: createdAt } = resource;

  return (
    <Grid item xs={10} sm={6} md={3}>
      <Card sx={{ position: 'relative' }}>
        <CardContent
          sx={{
            pt: 4,
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={9}>
              <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
                {fDate(createdAt)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                gutterBottom
                variant="body2"
                sx={{ color: 'text.disabled', display: 'block', textAlign: 'right', textDecoration: 'none' }}
                title={t('Edit_this')}
                component={RouterLink}
                to={`/dashboard/resources/edit/${hashid}`}
              >
                <Icofont icon="edit" />
              </Typography>
            </Grid>
          </Grid>
          <TitleStyle to="#" color="inherit" variant="subtitle2" underline="hover" component={RouterLink}>
            {name}
          </TitleStyle>
          <Typography paragraph variant="h1" sx={{ color: 'text.primary', display: 'block', textAlign: 'center' }}>
            <Icofont icon={icon} />
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: 'text.disabled',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {url}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
