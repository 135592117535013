import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Paper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: 'none',
  borderRadius: '8px',
  textAlign: 'center',
}));

const ErrorModal = ({ open, handleClose, errorMessage }) => {
  return (
    <StyledModal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Paper>
        <Typography variant="h5" id="modal-title">
          Error
        </Typography>
        <Typography variant="body1" id="modal-description">
          {errorMessage}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleClose} style={{ marginTop: '16px' }}>
          Close
        </Button>
      </Paper>
    </StyledModal>
  );
};

export default ErrorModal;
