import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from '@mui/material';
// components

import { OpenInNew } from '@mui/icons-material';

import { ConfirmationDialog } from '../../components/general/ConfirmationDialog';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from '../../hooks/useQuery';
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from '../../hooks/useApi';
import AvailabilityCalendar from './AvailabilityCalendar';

const override = {
  margin: '0 auto',
};

export default function AvailabilityIndex() {
  const [bulletins, setBulletins] = useState([]);



  const fetchData = useQuery();
  const { t } = useTranslation();

  // const refreshTable = async () => {
  //   setIsLoading(true);
  //   await fetchData('bulletins', filter, sort, setBulletins, sortField, setPagination, page, setNotFound);
  //   setIsLoading(false);
  // };

  return (
    <Page title={t('availability')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('availability')}
          </Typography>

        </Stack>

        <Card>

          <AvailabilityCalendar />

        </Card>
      </Container>
    </Page>
  );
}
