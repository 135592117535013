import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// material
import { Card, Stack, Container, Typography } from '@mui/material';
import AnnouncementForm from '../../sections/@dashboard/announcements/AnnouncementForm';
// components
import Page from '../../components/Page';
// mock
import tracker from '../../api/tracker';

// ---------------------------------------------------------------------

const override = {
  // display: "block",
  margin: '0 auto',
  // borderColor: "red",
};

export default function EditAnnouncement() {
  const [loading, setLoading] = useState(true);
  const { hashid } = useParams();
  const [announcement, setAnnouncement] = useState();
  const { t } = useTranslation();
  const fetchUserData = async () => {
    const response = await tracker.get(`announcements/${hashid}`);
    setAnnouncement(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, [hashid]);

  return (
    <Page title={t('announcement_form')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('announcement_form')}
          </Typography>
        </Stack>
        <Card style={{ padding: '50px' }}>
          {loading ? (
            <FadeLoader color="blue" cssOverride={{ margin: '0 auto' }} loading={loading} />
          ) : (
            <AnnouncementForm announcement={announcement} />
          )}
        </Card>
      </Container>
    </Page>
  );
}
