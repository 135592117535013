import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ConstructionOutlined, Title } from '@mui/icons-material';
import { color } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  Box,
  Grid,
  Divider,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import MyAccordion from '../../components/MyAccordion';
import { ConfirmationDialog } from '../../components/general/ConfirmationDialog';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from '../../hooks/useQuery';
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from '../../hooks/useApi';
import FreeSelect from '../../components/hook-form/FreeSelect';
import DocRequestForm from '../../sections/@dashboard/docRequest/DocRequestForm';
import tracker from '../../api/tracker';

const override = {
  margin: '0 auto',
};

export default function DocRequestView() {
  const [docRequest, setDocRequest] = useState([]);
  const { hashid } = useParams();
  const [selectedDocRequestView, setSelectedDocRequestView] = useState();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('asc');
  const [sortField, setSortField] = useState('created_at');
  const [selected, setSelected] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { doDelete } = useApi();
  const [loading, setLoading] = useState(true);

  const [userInterests, setUserInterests] = useState();
  const fetchData = useQuery();
  const { t } = useTranslation();
  const [createdAt, setCreatedAt] = useState();
  const [baptism, setBaptism] = useState();
  const [firstCommunion, setFirstCommunion] = useState();
  const [confirmation, setConfirmation] = useState();
  const [matrimony, setMatrimony] = useState();
  const [recipientName, setRecipientName] = useState();
  const [recipientBirth, setRecipientBirth] = useState();
  const [conferredDate, setConferredDate] = useState();
  const [recipientMother, setRecipientMother] = useState();
  const [recipientFather, setRecipientFather] = useState();
  const [witnesses, setWitnesses] = useState();
  const [requestorName, setRequestorName] = useState();
  const [recipientRelationship, setRecipientRelationship] = useState();
  const [requestReason, setRequestReason] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [status, setStatus] = useState(' ');
  const [type, setType] = useState(' ');
  const [method, setMethod] = useState();
  const [groomName, setGroomName] = useState();
  const [brideName, setBrideName] = useState();
  const [address, setAddress] = useState('');

  const getStatusColor = (status) => {
    const style = {
      paddingLeft: '10px',
      fontWeight: 'bold',
      paddingBottom: '1px',
      paddingRight: '10px',
      paddingTop: '1px',
      borderRadius: '30px',
    };
    if (status === 'rejected') {
      style.color = '#FF003F';
      style.backgroundColor = '#F8E3E1';
    } else if (status === 'pending') {
      style.color = '#6d8ee9';
      style.backgroundColor = '#e0e9fa';
    } else if (status === 'accepted') {
      style.color = '#6ca181';
      style.backgroundColor = '#dff8e7';
    } else if (status === 'placed') {
      style.color = '#d1b573';
      style.backgroundColor = '#fcf9ce';
    }

    return style;
  };

  const fetchDocRequestViewData = async () => {
    setLoading(true);
    const response = await tracker.get(`docRequests/${hashid}`);
    const docRequest = response.data.data;

    setDocRequest(response.data.data);
    setCreatedAt(response.data.data.created_at);
    setType(response.data.data.type)

    setBaptism(response.data.data.baptism);
    setFirstCommunion(response.data.data.first_communion);
    setConfirmation(response.data.data.confirmation);

    setMatrimony(response.data.data.matrimony);
    setRecipientName(response.data.data.recipient_name);

    setRecipientBirth(response.data.data.recipient_birth);

    setConferredDate(response.data.data.conferred_date);
    setRecipientMother(response.data.data.recipient_mother);
    setRecipientFather(response.data.data.recipient_father);
    setWitnesses(response.data.data.witnesses);
    setRequestorName(response.data.data.requestor_name);

    setRecipientRelationship(response.data.data.recipient_relationship);
    setAddressLine1(response.data.data.address_line_1);
    setAddressLine2(response.data.data.address_line_2);
    setEmail(response.data.data.email);
    setMethod(response.data.data.method);
    setRequestReason(response.data.data.request_reason);
    setGroomName(response.data.data.groom_name);
    setBrideName(response.data.data.bride_name);

    setStatus(response.data.data.status);
    setPhone(response.data.data.phone);
    const address = {
      line_1: docRequest.address_line_1,
      line_2: docRequest.address_line_2,
      city: docRequest.city,
      state: docRequest.state,
      zip: docRequest.zip,
    };

    setAddress(address);
    setLoading(false);
  };

  useEffect(() => {
    fetchDocRequestViewData();
  }, [hashid]);
  return (
    <Page title={t('doc_Requests')}>
      <Container>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box>
            {loading ?   <FadeLoader color={'blue'} loading={loading} cssOverride={override} size={50} /> :(
              <>
                <Typography variant="h6" gutterBottom>
                  {t('request_view')} {t(type)} <span style={getStatusColor(status)}>{t(status)}</span>
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">
                      <strong>{t('recipient_name')}</strong>: {recipientName || t('None')}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>{t('recipient_birth')}</strong>: {recipientBirth}
                    </Typography>
                    {type === 'matrimony' && (
                      <>
                        <Typography variant="subtitle1">
                          <strong>{t('groom_name')}</strong>: {groomName}
                        </Typography>
                        <Typography variant="subtitle1">
                          <strong>{t('bride_name')}</strong>: {brideName}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">
                      <strong>{t('recipient_mother')}</strong>: {recipientMother}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>{t('recipient_father')}</strong>: {recipientFather}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>{t('conferred_date')}</strong>: {conferredDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">
                      <strong>{t('date')}</strong>: {createdAt}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>{t('address')}</strong>: {address.line_1}
                      {address.line_2 && `, ${address.line_2}`}, {address.city}, {address.state} {address.zip}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>{t('phone')}</strong>: {phone}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>{t('status')}</strong>: {t(status)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">
                      <strong>{t('email')}</strong>: {email}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>{t('method')}</strong>: {method}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )
            }


          </Box>
        </Card>
      </Container>
      <Container style={{ marginTop: '20px' }}>
        <Card sx={{padding: '30px'}}>
          <DocRequestForm docRequest={docRequest} />
        </Card>
      </Container>
    </Page>
  );
}
