import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Stack, IconButton, InputAdornment, TextField, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from '../../../components/hook-form/RHFSelect';
import tracker from '../../../api/tracker';
import CheckboxField from './CheckboxField';

import RHFFileInput from '../../../components/hook-form/RHFFileInput';

// ----------------------------------------------------------------------

export default function BaptismForm({ baptismView }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [massDate, setMassDate] = useState(null);
  const [datePickerEl, setDatePickerAnchorElement] = useState(null);

  const [massDate1, setMassDate1] = useState(null);
  const [datePickerEl1, setDatePickerAnchorElement1] = useState(null);

  const [massDate2, setMassDate2] = useState(null);
  const [datePickerEl2, setDatePickerAnchorElement2] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const STATUS_OPTIONS = [
    { label: 'pending', value: 'pending' },
    { label: 'completed', value: 'completed' },
    { label: 'rejected', value: 'rejected' },
  ];
  const RoleList = [
    { label: 'Spanish', value: 'epanish' },
    { label: 'English', value: 'english' },
  ];
  const fetchRoleData = async () => {
    // console.log(baptismView.status);
    // setStatusList(baptismView.status);
  };

  useEffect(() => {
    if (baptismView) {
      const fields = [
        'receipt_number',
        'parish_name',
        'donation_made',
        'birth_cert_verified',
        'permission_needed',
        'parish_language',
        'status',
        'class_date',
        'baptism_date',
        'payment_date',
      ];
      fields.forEach((field) => setValue(field, baptismView[field]));
      console.log(baptismView.baptism_date);
      if (baptismView.baptism_date) {
        setMassDate1(dayjs(baptismView.baptism_date));
      }
      // const dateEdit = baptismView.baptism_date.replace(/-/g, '/');
      // const dateStart = new Date(dateEdit);
      // setMassDate1(dateStart);

      console.log(baptismView.mother_email);
    }

    fetchRoleData();
  }, [baptismView]);

  const UserSchema = Yup.object().shape({
    // password: Yup.string().required(t('Required')),
  });

  const defaultValues = {
    parish_name: '',
    status: '',
    parish_language: '',
  };
  // if(!user)
  //   user = defaultValues

  const methods = useForm({
    resolver: yupResolver(UserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if (!baptismView)
      await tracker.post('register', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    else
      await tracker.post(`baptism/${baptismView.hashid}/update`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    navigate('/dashboard/baptism', { replace: true });
  };
  function formatDate(datee) {
    // Extraemos los componentes de la fecha

    const date = new Date(datee);
    // console.log(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ajustamos el mes para que tenga 2 dígitos
    const day = String(date.getDate()).padStart(2, '0'); // Ajustamos el día para que tenga 2 dígitos
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Componemos la fecha en el formato deseado (YYYY-MM-DD)
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    // console.log(formattedDate);
    return formattedDate;
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="parish_name" label={t('parish_name')} />
        </Stack>
        <RHFSelect name="parish_language" label={t('parish_language')} options={RoleList} />
        <RHFSelect name="status" label={t('status')} options={STATUS_OPTIONS} />

        <CheckboxField name="donation_made" label={t('donation_made')} />
        <CheckboxField name="birth_cert_verified" label={t('birth_cert_verified')} />
        <CheckboxField name="permission_needed" label={t('permission_needed')} />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            label={t('baptism_date')}
            value={massDate1}
            anchorEl={datePickerEl1}
            onChange={(newValue) => {
              const sd1 = formatDate(newValue);
              setValue('baptism_date', sd1);
              setMassDate1(newValue);
              // console.log(massDate);
            }}
            renderInput={(params) => (
              <TextField {...params} onClick={(event) => setDatePickerAnchorElement1(event.currentTarget)} />
            )}
          />
        </LocalizationProvider>

        <input type="hidden" name="hashid" />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('Save')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
