import PropTypes from 'prop-types';
// material
import { MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tracker from '../api/tracker';

// ----------------------------------------------------------------------

SortDropdown.propTypes = {
  sort: PropTypes.string,
  setSort: PropTypes.func,
};

export default function SortDropdown({ sort, setSort, options, ...other }) {
  const [t] = useTranslation();
  let sortOptions = [];
  if (!options) {
    sortOptions.push({ value: 'desc', label: t('Latest') });
    sortOptions.push({ value: 'asc', label: t('Oldest') });
  } else sortOptions = options;

  const handleSort = (event) => {
    setSort(event.target.value);
  };

  return (
    <TextField sx={{ height: '20px' }} select size="large" value={sort} onChange={handleSort} {...other}>
      {sortOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
