import {React, useState} from 'react';
import Button from '@mui/material/Button';
import { Alert, Stack } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { RHFTextField } from '../hook-form';
import FreeTextField from '../hook-form/FreeTextField';



export function PinDialog(props) {
  const { onClose,onSubmit, message, open, loading, free, ...other } = props;
  const {t} = useTranslation();
  const [pin, setPin] = useState(null)
  const handleChange = (event) => {
    // 👇 Get input value from "event"
    setPin(event.target.value);
  };


  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      
      <DialogTitle>{"Ship Order"}</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          
          <Alert severity="info"> {t(message)}</Alert>
          {!free ?  <RHFTextField type='password' autoComplete="new-password" length={5} name="action_pin" label={t('trackin_numbers')} /> 
          : <FreeTextField type='text' autoComplete="new-password" onChange={handleChange}  length={5} name="action_pin" label={t('trackin_numbers')} /> } 
         
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {t('cancel')}
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={free ? () => {console.log(pin);onSubmit(pin)} :onSubmit}>{t('save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}