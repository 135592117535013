import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Grid,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import { ConfirmationDialog } from '../../components/general/ConfirmationDialog';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from '../../hooks/useQuery';
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from '../../hooks/useApi';
import CarrouselCard from '../../sections/@dashboard/carrousels/CarrouselCard';

const override = {
  margin: '0 auto',
};

export default function CarrouselIndex() {
  const [carrousels, setCarrousels] = useState([]);
  const [selectedCarrousel, setSelectedCarrousel] = useState();
  const [pagination, setPagination] = useState({ total: 0, per_page: 0, current_page: 1, last_page: 1 });
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('desc');
  const [sortField, setSortField] = useState('created_at');
  const [selected, setSelected] = useState([]);
  const [selectedHashid, setSelectedHashid] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { doDelete } = useApi();
  const [loading, setLoading] = useState(true);

  const fetchData = useQuery();
  const { t } = useTranslation();

  const refreshTable = async () => {
    setIsLoading(true);
    await fetchData('carrousels', filter, sort, setCarrousels, sortField, setPagination, page, setNotFound);
    console.log(carrousels);
    setIsLoading(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(refreshTable, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [filter, sort, page, sortField]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = carrousels.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteDialog = (hashid) => {
    setSelectedHashid(hashid);
    setShowDeleteDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleDelete = async () => {
    setLoading(true);
    doDelete(`carrousels/${selectedHashid}`, t('delete_success'), t('delete_error'), refreshTable);
    setLoading(false);
    setShowDeleteDialog(false);
    setSelected([]);
  };
  const handleChangeRowsPerPage = (event) => {
    setLoading(true);
    doDelete(`carrousels/${selectedCarrousel.hashid}`, t('delete_success'), t('delete_error'), refreshTable);
    setLoading(false);
    setShowDeleteDialog(false);
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilter(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - carrousels.length) : 0;

  return (
    <Page title={t('carrousels')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('carrousels')}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/carrousel/save"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {t('new_carrousel')}
          </Button>
        </Stack>

        <Card sx={{minHeight: '300px'}}>
          <Grid container spacing={3} sx={{padding: '30px'}}>
            {isLoading ? (
              <FadeLoader
                color={'blue'}
                loading={isLoading}
                cssOverride={{
                  margin: '0 auto',
                  marginTop: '120px'
                }}
                size={50}
              />
            ) : (
              carrousels.map((carrousel, index) => (
                <CarrouselCard
                  onDelete={handleDeleteDialog}
                  key={carrousel.hashid}
                  carrousel={carrousel}
                  index={index}
                />
              ))
            )}
          </Grid>
          <ConfirmationDialog
            open={showDeleteDialog}
            onClose={() => {
              setShowDeleteDialog(false);
            }}
            onConfirm={handleDelete}
            message={t('delete_confirm')}
          />
        </Card>
      </Container>
    </Page>
  );
}
