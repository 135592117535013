import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import tracker from '../api/tracker';
import useNotification from './useNotificaction';

export const useApi = () => {
  const [sendNotification] = useNotification();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const doDelete = async (
    url,
    successMessage,
    invalidMessage,
    successCallback = null,
    payload = null,
    mass = false
  ) => {
    const method = mass ? tracker.post : tracker.delete;
    const result = await method(url, payload).catch((result) => {
      if (result.response.status === 422) {
        sendNotification({ msg: invalidMessage, variant: 'error' });
        if (mass && successCallback) successCallback();
      } else if (result.response.status === 423) {
        sendNotification({ msg: t('cant_delete_self'), variant: 'error' });
      } else sendNotification({ msg: t('something_went_wrong'), variant: 'error' });
    });
    if (result && result.status === 200) {
      if (successCallback) successCallback();
      sendNotification({ msg: successMessage, variant: 'success' });
    }
  };

  const doSave = async (
    url,
    data,
    successMessage,
    redirectTo = null,
    model = null,
    headers = {},
    partialSuccessMessage = '',
    secondaryUrl = ''
  ) => {
    let response;

      try {
          if(!model)
              response = await tracker.post(url, data, headers)
          else {
              if(!secondaryUrl)
                data._method = "PUT"
              response = await tracker.post(`${url}/${model.hashid}${secondaryUrl}`, data, headers)
          }
      }
      catch(error){
          return error
      }
      if(response.data && response.data.success === false)
          sendNotification({msg: partialSuccessMessage, variant: 'warning'})
      else
          sendNotification({msg: successMessage, variant: 'success'})
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(50);
      if(response.status === 200 && redirectTo)
          navigate(redirectTo, { replace: true });
      return response;
  }

  const doPost = async (url,
                        data,
                        successMessage, headers = {}) => {
    const response = await tracker.post(`${url}`, data, headers)
    return response

  }
  
  return { doDelete, doSave, doPost };
};
