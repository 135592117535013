import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';
import {useTranslation} from "react-i18next";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
    const {t} = useTranslation();
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
          {t('Not_found')}
      </Typography>
      <Typography variant="body2" align="center">
          {t('No_results_for')} &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>.
      </Typography>
    </Paper>
  );
}
