import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui

import dayjs from 'dayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { Stack, Link, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css';
import { paymentTypes } from '../../../constants';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from '../../../components/hook-form/RHFSelect';
import { useApi } from '../../../hooks/useApi';
import tracker from '../../../api/tracker';
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import { PinDialog } from '../../../components/general/PinDialog';

// ----------------------------------------------------------------------

export default function AttendanceForm({ attendance }) {
  const { t } = useTranslation();
  const { doSave } = useApi();
  const [roles, setRoles] = useState([]);
  const [showPinDialog, setShowPinDialog] = useState(false);
  const [pinDialogMessage, setPinDialogMessage] = useState(false);
  const defaultValue = new Date('2022-04-17T15:30');
  const [datePickerEl, setDatePickerAnchorElement] = useState(null);
  const [massDate, setMassDate] = useState(null);

  const fetchRoles = async () => {
    const result = await tracker.get('roles');
    setRoles(result.data.data);
  };

  useEffect(() => {
    if (attendance) {
      const fields = ['attendance', 'info', 'date'];
      fields.forEach((field) => setValue(field, attendance[field]));
    }
    fetchRoles();
  }, [attendance]);

  const ModelSchema = Yup.object().shape({
    attendance: Yup.string().required(t('Required')),
  });

  const defaultValues = {
    attendance: '',
  };

  const methods = useForm({
    resolver: yupResolver(ModelSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
  } = methods;

  const onSubmit = async (data) => {
    console.log(data);
    const hashid = watch('hashid');
    const requestData = { ...data, hashid };
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await doSave(
      'attendance',
      data,
      t('attendances_saved'),
      '/dashboard/attendance/',
      attendance,
      headers
    );
    if (response.status === 423) {
      setPinDialogMessage(response.response.data.message);
      setShowPinDialog(true);
    }
  };

  function formatDate(datee) {
    // Extraemos los componentes de la fecha

    const date = new Date(datee);
    // console.log(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ajustamos el mes para que tenga 2 dígitos
    const day = String(date.getDate()).padStart(2, '0'); // Ajustamos el día para que tenga 2 dígitos
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Componemos la fecha en el formato deseado (YYYY-MM-DD)
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    // console.log(formattedDate);
    return formattedDate;
  }
  const handleDateTimeChange = (newValue) => {
    setMassDate(newValue);
    // setValue('date_start', sd);
    console.log(massDate);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1} direction={'column'}>
          <RHFTextField
            name="attendance"
            label={t('Attendances')}
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              label={t('Mass_day')}
              value={massDate}
              anchorEl={datePickerEl}
              onChange={(newValue) => {
                const sd = formatDate(newValue);
                setValue('date', sd);
                setMassDate(newValue);
                // console.log(massDate);
              }}
              renderInput={(params) => (
                <TextField {...params} onClick={(event) => setDatePickerAnchorElement(event.currentTarget)} />
              )}
            />
          </LocalizationProvider>
          <RHFTextField name="info" label={t('details')} />
        </Stack>
        <input type="hidden" name="id" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/dashboard/attendance">
          {t('go_back')}
        </Link>
      </Stack>
    </FormProvider>
  );
}
