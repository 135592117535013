import { Navigate, Outlet } from 'react-router-dom'
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import tracker from "../api/tracker";


const PrivateRoutes = () => {
    const { user } = useSelector(state => state.user);
    const [auth, setAuth] = useState(user ? {'token': user.token} : {'token': false})

    const getUserData = async () => {
        try{
             await tracker.get('check')
        }
        catch (e){
            setAuth({'token': false})
        }

    }
    useEffect( () => {
        getUserData()
    })
    return (
        auth.token ? <Outlet/> : <Navigate to='/login'/>
    )
}

export default PrivateRoutes