import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from '../../../components/hook-form/RHFSelect';
import tracker from '../../../api/tracker';
import RHFFileInput from '../../../components/hook-form/RHFFileInput';

// ----------------------------------------------------------------------

export default function UserForm({ user }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const fetchRoleData = async () => {
    const result = await tracker.get('roles');
    setRoleList(result.data.data);
  };

  useEffect(() => {
    if (user) {
      const fields = ['email', 'name', 'hashid', 'role_hash'];
      fields.forEach((field) => setValue(field, user[field]));
    }

    fetchRoleData();
  }, [user]);

  const UserSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    // password: Yup.string().required(t('Required')),
    email: Yup.string().email(t('Valid_email')).required('Required'),
    role_hash: Yup.string().required(t('Required')),
    picture: Yup.mixed().test('fileSize', 'The file is too large', (value) => {
      console.log(value);
      return true;
    }),
  });

  const defaultValues = {
    name: '',
    password: '',
    email: '',
    role_hash: '',
    picture: null,
  };
  // if(!user)
  //   user = defaultValues

  const methods = useForm({
    resolver: yupResolver(UserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if (data.picture) {
      const file = data.picture[0];
      console.log(`Original file size: ${(file.size / 1024 / 1024).toFixed(2)} MB`);
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      setValue('picture', compressedFile);
    } else data.picture = null;

    // console.log(data.picture);
    if (!user)
      await tracker.post('register', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    else
      await tracker.post(`users/update/${user.hashid}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    navigate('/dashboard/user', { replace: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="name" label={t('Nombre')} />
        </Stack>
        <RHFSelect name="role_hash" label={t('Role')} options={roleList} />
        <RHFTextField name="email" label={t('Correo')} />
        <input type="hidden" name="hashid" />
        {!user ? (
          <RHFTextField
            name="password"
            label={t('Contraseña')}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          false
        )}
        <RHFFileInput name="picture" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('Save')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
