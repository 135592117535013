import * as Yup from 'yup';
import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui

import { Stack, Link, TextField, Typography, IconButton, Alert, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css';
import { OpenInNew } from '@mui/icons-material';
import Label from '../../../components/Label';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from '../../../components/hook-form/RHFSelect';
import { useApi } from '../../../hooks/useApi';
import tracker from '../../../api/tracker';
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import { PinDialog } from '../../../components/general/PinDialog';

// ----------------------------------------------------------------------

export default function DocRequestForm({ docRequest }) {
  const { t } = useTranslation();
  const { doSave } = useApi();
  const [roles, setRoles] = useState([]);
  const [showPinDialog, setShowPinDialog] = useState(false);
  const [pinDialogMessage, setPinDialogMessage] = useState(false);
  const defaultValue = new Date('2022-04-17T15:30');
  const [datePickerEl, setDatePickerAnchorElement] = useState(null);
  const [massDate, setMassDate] = useState(null);
  const [productPhotos, setProductPhotos] = useState([]);

  const fetchRoles = async () => {
    const result = await tracker.get('roles');
    setRoles(result.data.data);
  };

  useEffect(() => {
    if (docRequest) {
      const fields = ['phone', 'email', 'created_at'];
      fields.forEach((field) => setValue(field, docRequest[field]));
    }
    fetchRoles();
    // console.log(bulletin);
  }, [docRequest]);

  const ModelSchema = Yup.object().shape({
    // document: Yup.array().required(t('Required')),
  });

  const defaultValues = {
    title: '',
  };

  const methods = useForm({
    resolver: yupResolver(ModelSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
  } = methods;

  const onSubmit = async (data) => {
    if (!data.document || !data.document[0]) {
      // Si data.image es undefined o no tiene elementos

      console.log('Image data is missing');
      // Aquí puedes mostrar un mensaje o realizar alguna acción adecuada
      return;
    }

    data.document = data.document[0];
    console.log(data);
    // console.log(data);
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await doSave(
      'docRequests',
      data,
      t('doc_request_sent'),
      `/dashboard/docRequest`,
      docRequest,
      headers,
      null,
      '/uploadFile'
    );
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {docRequest && docRequest.document_sent && (
          <>
            <Alert severity={'info'}> {t('document_sent')}</Alert>
            <Typography variant="subtitle1">
              <strong>{t('sent_document')}</strong>:{' '}
              <IconButton component="a" href={docRequest.document} target="_blank" rel="noopener noreferrer">
                <OpenInNew />
              </IconButton>
            </Typography>
            <Divider />
          </>
        )}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Label sx={{ marginBottom: '5px' }}>{t('document_upload')}</Label>
          <RHFFileInput name="document" />
        </div>

        <input type="hidden" name="id" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('Upload_and_Send')}
        </LoadingButton>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/dashboard/docRequest">
          {t('go_back')}
        </Link>
      </Stack>
    </FormProvider>
  );
}
