import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ConstructionOutlined, Title } from '@mui/icons-material';
import { color } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  Box,
  Grid,
  Divider,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import MyAccordion from '../../components/MyAccordion';
import { ConfirmationDialog } from '../../components/general/ConfirmationDialog';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from '../../hooks/useQuery';
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from '../../hooks/useApi';
import FreeSelect from '../../components/hook-form/FreeSelect';
import BaptismForm from '../../sections/@dashboard/baptisms/BaptismForm';

import tracker from '../../api/tracker';

const override = {
  margin: '0 auto',
};

export default function BaptismView() {
  const [baptismViews, setBaptismViews] = useState([]);
  const { hashid } = useParams();
  console.log(hashid);
  const [selectedBaptismView, setSelectedBaptismView] = useState();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('asc');
  const [sortField, setSortField] = useState('created_at');
  const [selected, setSelected] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { doDelete } = useApi();
  const [loading, setLoading] = useState(true);

  const [userInterests, setUserInterests] = useState();
  const fetchData = useQuery();
  const { t } = useTranslation();

  const [status, setStatus] = useState('');
  const [donationMade, setDonationMade] = useState(false); // Puedes inicializar con false si deseas
  const [childName, setChildName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [birthPlace, setBirthPlace] = useState('');
  const [motherName, setMotherName] = useState('');
  const [motherPhone, setMotherPhone] = useState('');
  const [motherEmail, setMotherEmail] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [fatherPhone, setFatherPhone] = useState('');
  const [fatherEmail, setFatherEmail] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState(null);
  const [godfatherName, setGodfatherName] = useState('');
  const [godfatherReligion, setGodfatherReligion] = useState('');
  const [godfatherPhone, setGodfatherPhone] = useState('');
  const [godfatherEmail, setGodfatherEmail] = useState('');
  const [godmotherName, setGodmotherName] = useState('');
  const [godmotherReligion, setGodmotherReligion] = useState('');
  const [godmotherPhone, setGodmotherPhone] = useState('');
  const [godmotherEmail, setGodmotherEmail] = useState('');
  const [birthCertVerified, setBirthCertVerified] = useState(null);
  const [permissionNeeded, setPermissionNeeded] = useState(null);
  const [parishName, setParishName] = useState(null);
  const [parishLanguage, setParishLanguage] = useState(null);
  const [classDate, setClassDate] = useState(null);
  const [baptismDate, setBaptismDate] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [receiptNumber, setReceiptNumber] = useState(null);
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [trackingText, setTrackingText] = useState(' ');
  const [formattedCreatedAt, setFormattedCreatedAt] = useState();
  const [baptismView, setBaptismView] = useState();
  const [address, setAddress] = useState('');

  const fetchBaptismViewData = async () => {
    setLoading(true);
    const response = await tracker.get(`baptism/${hashid}`);
    const baptism = response.data.data;
    setPaymentDate(baptism.payment_date);
    setClassDate(baptism.class_date);
    setReceiptNumber(baptism.receipt_number);
    setBaptismView(response.data.data);
    setStatus(baptism.status);
    setDonationMade(baptism.donation_made);
    setChildName(baptism.child_name);
    setBirthDate(baptism.birth_date);
    setBirthPlace(baptism.birth_place);
    setMotherName(baptism.mother_name);
    setMotherPhone(baptism.mother_phone);
    setMotherEmail(baptism.mother_email);
    setFatherName(baptism.father_name);
    setFatherPhone(baptism.father_phone);
    setFatherEmail(baptism.father_email);
    setAddressLine1(baptism.address_line_1);
    setAddressLine2(baptism.address_line_2);
    setGodfatherName(baptism.godfather_name);
    setGodfatherReligion(baptism.godfather_religion);
    setGodfatherPhone(baptism.godfather_phone);
    setGodfatherEmail(baptism.godfather_email);
    setGodmotherName(baptism.godmother_name);
    setGodmotherReligion(baptism.godmother_religion);
    setGodmotherPhone(baptism.godmother_phone);
    setGodmotherEmail(baptism.godmother_email);
    setBirthCertVerified(baptism.birth_cert_verified);
    setPermissionNeeded(baptism.permission_needed);
    setParishName(baptism.parish_name);
    setParishLanguage(baptism.parish_language);
    setClassDate(baptism.class_date);
    setBaptismDate(baptism.baptism_date);
    setPaymentDate(baptism.payment_date);
    setReceiptNumber(baptism.receipt_number);
    setCreatedAt(baptism.created_at);
    setUpdatedAt(baptism.updated_at);

    const address = {
      line_1: baptism.address_line_1,
      line_2: baptism.address_line_2,
      city: baptism.city,
      state: baptism.state,
      zip: baptism.zip,
    };
    console.log(address);
    setAddress(address);
    setLoading(false);
  };

  useEffect(() => {
    fetchBaptismViewData();
  }, [hashid]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = baptismView.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteDialog = (baptismView) => {
    setSelectedBaptismView(baptismView);
    setShowDeleteDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilter(event.target.value);
  };

  const getStatusColor = (status) => {
    const style = {
      paddingLeft: '10px',
      fontWeight: 'bold',
      paddingBottom: '1px',
      paddingRight: '10px',
      paddingTop: '1px',
      borderRadius: '30px',
    };
    if (status === 'cancel') {
      style.color = '#FF003F';
      style.backgroundColor = '#F8E3E1';
    } else if (status === 'shipped') {
      style.color = '#6d8ee9';
      style.backgroundColor = '#e0e9fa';
    } else if (status === 'delivered') {
      style.color = '#6ca181';
      style.backgroundColor = '#dff8e7';
    } else if (status === 'placed') {
      style.color = '#d1b573';
      style.backgroundColor = '#fcf9ce';
    }

    return style;
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - baptismViews.length) : 0;
  console.log(status);
  const wStatusCapitalized = status.charAt(0).toUpperCase() + status.slice(1);
  return (
    <Page title={t('User Details')}>
      <Container>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box>
            <Typography variant="h6" gutterBottom>
              {t('User Information')}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>{t('receipt_number')}:</strong> {receiptNumber}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t('Status')}:</strong> {status}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t('Donation_Made')}:</strong> {donationMade ? t('Yes') : t('No')}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t('Child_Name')}:</strong> {childName}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t('Birth_Date')}:</strong> {birthDate}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t('Birth_Place')}:</strong> {birthPlace}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Mother's_Name")}:</strong> {motherName}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Mother's_Phone")}:</strong> {motherPhone}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Mother's_Email")}:</strong> {motherEmail}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Father's_Name")}:</strong> {fatherName}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Father's_Phone")}:</strong> {fatherPhone}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Father's_Email")}:</strong> {fatherEmail}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t('Address_Line_1')}:</strong> {addressLine1}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t('Address_Line_2')}:</strong> {addressLine2 || t('N/A')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>{t("Godfather's_Name")}:</strong> {godfatherName}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Godfather's_Religion")}:</strong> {godfatherReligion}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Godfather's_Phone")}:</strong> {godfatherPhone}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Godfather's_Email")}:</strong> {godfatherEmail}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Godmother's_Name")}:</strong> {godmotherName}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Godmother's_Religion")}:</strong> {godmotherReligion}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Godmother's_Phone")}:</strong> {godmotherPhone}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t("Godmother's_Email")}:</strong> {godmotherEmail}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t('payment_date')}:</strong> {paymentDate}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>{t('class_date')}:</strong> {classDate}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>

      <Container style={{ marginTop: '20px' }}>
        <Card sx={{ padding: '30px' }}>
          <BaptismForm baptismView={baptismView} />
        </Card>
      </Container>
    </Page>
  );
}
