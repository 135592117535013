
import { FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
// mock
import tracker from "../../api/tracker";
import UserForm from "../../sections/@dashboard/user/UserForm";



// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function EditUser() {

  const [loading, setLoading] = useState(true)
  const { hashid } = useParams()
  const [user, setUser] = useState()
  const { t } = useTranslation();
  const fetchUserData = async () =>{
      const response = await tracker.get(`users/${hashid}`)
    setUser(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchUserData()
  }, [hashid])

  return (
    <Page title={t('User_form')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('User_form')}
          </Typography>
        </Stack>
        <Card style={{padding: "50px"}}>
          {loading ? <FadeLoader color="blue" cssOverride={{margin: "0 auto"}} loading={loading} />
              :  <UserForm user={user} />}

        </Card>
      </Container>
    </Page>
  );
}
