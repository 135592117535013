import React from 'react';
import ChildrenDetails from './ChildrenDetails'; // Importa el componente ChildrenDetails

const ParentDetailsView = ({ children }) => {
  return (
    <div  style={{padding: '10px'}}>
      <ChildrenDetails children={children} />
    </div>
  );
};

export default ParentDetailsView;
