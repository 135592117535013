// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Stats',
    path: '/dashboard/app',
    icon: getIcon('mdi:finance'),
    allowedRoles: ['Administrator'],
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
    allowedRoles: ['Administrator'],
  },
  {
    title: 'bulletins',
    path: '/dashboard/bulletin',
    icon: getIcon('akar-icons:paper'),
    allowedRoles: ['Administrator'],
  },
  {
    title: 'carrousels',
    path: '/dashboard/carrousel',
    icon: getIcon('ant-design:picture-filled'),
    allowedRoles: ['Administrator'],
  },
  {
    title: 'attendances',
    path: '/dashboard/attendance',
    icon: getIcon('ic:round-co-present'),
    allowedRoles: ['Administrator', 'Typer'],
  },
  {
    title: 'calendar',
    path: '/dashboard/availability',
    icon: getIcon('fluent:shifts-availability-24-filled'),
    allowedRoles: ['Administrator'],
  },
  {
    title: 'announcements',
    path: '/dashboard/announcement',
    icon: getIcon('lets-icons:paper'),
    allowedRoles: ['Administrator', 'Catechist'],
  },
  {
    title: 'meetings',
    path: '/dashboard/meeting',
    icon: getIcon('guidance:meeting-room'),
    allowedRoles: ['Administrator'],
  },
  {
    title: 'registrations',
    path: '/dashboard/registration',
    icon: getIcon('medical-icon:registration'),
    allowedRoles: ['Administrator'],
  },
  {
    title: 'docRequests',
    path: '/dashboard/docRequest',
    icon: getIcon('teenyicons:doc-outline'),
    allowedRoles: ['Administrator'],
  },
  {
    title: 'baptisms',
    path: '/dashboard/baptism',
    icon: getIcon('tabler:bowl-filled'),
    allowedRoles: ['Administrator'],
  },
  {
    title: 'resources',
    path: '/dashboard/resources',
    icon: getIcon('akar-icons:shipping-box-02'),
    allowedRoles: ['Administrator'],
  },
];
export default navConfig;
