import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/users/User';
import Login from './pages/users/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import PrivateRoutes from './utils/PrivateRoute';
import SaveUser from './pages/users/SaveUser';
import EditUser from './pages/users/EditUser';
import RequestPasswordReset from './pages/users/RequestPassordReset';
import ResetPassword from './pages/users/ResetPassword';
import AttendanceIndex from './pages/attendances/AttendanceIndex';
import SaveAttendance from './pages/attendances/SaveAttendance';
import EditAttendance from './pages/attendances/EditAttendance';
import CarrouselIndex from './pages/carrousels/CarrouselIndex';
import BulletinIndex from './pages/bulletins/BulletinIndex';
import SaveBulletin from './pages/bulletins/SaveBulletin';
import EditBulletin from './pages/bulletins/EditBulletin';
import SaveCarrousel from './pages/carrousels/SaveCarrousel';
import EditCarrousel from './pages/carrousels/EditCarrousel';
import RegistrationIndex from './pages/registrations/RegistrationIndex';
import AnnouncementIndex from './pages/announcements/AnnouncementIndex';
import SaveAnnouncement from './pages/announcements/SaveAnnouncement';
import EditAnnouncement from './pages/announcements/EditAnnouncement';
import MeetingIndex from './pages/meeting/MeetingIndex';
import RegistrationView from './pages/registrations/RegistrationView';
import AvailabilityIndex from './pages/availability/AvailabilityIndex';
import ResourcesPage from './pages/resources/Resources';
import SaveResource from './pages/resources/SaveResource';
import DocRequestIndex from './pages/docRequests/DocRequestIndex';
import DocRequestView from './pages/docRequests/DocRequestView';
import BaptismIndex from './pages/baptisms/BaptismIndex';
import BaptismView from './pages/baptisms/BaptismView';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: <PrivateRoutes />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            { path: 'app', element: <DashboardApp /> },
            { path: 'user', element: <User /> },
            { path: 'user/save', element: <SaveUser /> },
            { path: 'user/edit/:hashid', element: <EditUser /> },
            { path: 'attendance', element: <AttendanceIndex /> },
            { path: 'attendance/edit/:hashid', element: <EditAttendance /> },
            { path: 'attendance/save', element: <SaveAttendance /> },
            { path: 'carrousel', element: <CarrouselIndex /> },
            { path: 'carrousel/save', element: <SaveCarrousel /> },
            { path: 'carrousel/edit/:hashid', element: <EditCarrousel /> },
            { path: 'bulletin', element: <BulletinIndex /> },
            { path: 'bulletin/edit/:hashid', element: <EditBulletin /> },
            { path: 'bulletin/save', element: <SaveBulletin /> },
            { path: 'announcement', element: <AnnouncementIndex /> },
            { path: 'announcement/save', element: <SaveAnnouncement /> },
            { path: 'announcement/edit/:hashid', element: <EditAnnouncement /> },
            { path: 'meeting', element: <MeetingIndex /> },
            { path: 'availability', element: <AvailabilityIndex /> },
            { path: 'registration', element: <RegistrationIndex /> },
            { path: 'registration/view/:hashid', element: <RegistrationView /> },
            { path: 'docRequest', element: <DocRequestIndex /> },
            { path: 'docRequest/view/:hashid', element: <DocRequestView /> },
            { path: 'baptism', element: <BaptismIndex /> },
            { path: 'baptism/view/:hashid', element: <BaptismView /> },
            { path: 'resources', element: <ResourcesPage /> },
            { path: 'resources/new', element: <SaveResource /> },
            { path: 'resources/edit/:resourceid', element: <SaveResource /> },
          ],
        },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'request-password-reset',
      element: <RequestPasswordReset />,
    },
    {
      path: 'reset-password/:hashid',
      element: <ResetPassword />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/user" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
