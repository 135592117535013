
import{useState, useEffect} from 'react'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import tracker from "../../api/tracker";
import {
  AppWebsiteVisits
} from '../../sections/@dashboard/app';
// sections


// ----------------------------------------------------------------------

export default function DailyBarChart({
  title,
  subheader,
  dataEndpoint,
  barDescription
}) {

  const {t} = useTranslation();
  const [dailyData, setDailyData] = useState({
    days: [],
    data: []
  });
  // const [monthlyRevenueYear, setMonthlyRevenueYear] = useState(moment().year());
  // const[yearList, setYearList] = useState([]);

  // const setYear = (event, value) => {
  //
  //     setMonthlyRevenueYear(value.props.value)
  // }


  const fetchDailyAttendance = async () => {
    const result = await tracker.get(`${dataEndpoint}`)
    const revenueInfo = {
      days: result.data.days.map((day) =>t(day) ),
      data: [
        {
          data: result.data.data,
          name: barDescription,
          type: 'column',
          fill: 'solid'
        }
      ]
    }
    setDailyData(revenueInfo)
  }
  console.log(dailyData)
  useEffect(() => {
    fetchDailyAttendance()
  }, [])
  return (
        <>
            <AppWebsiteVisits
              title={title}
              subheader={subheader}
              chartLabels={dailyData.days}
              chartData={dailyData.data}
              // yearList={yearList}
              // selectedYear={monthlyRevenueYear}
              // setYear={setYear}
            />
        </>
  );
}
