import { faker } from '@faker-js/faker';

// ----------------------------------------------------------------------

const POST_TITLES = [
  'Whiteboard Templates By Industry Leaders',
  'Tesla Cybertruck-inspired camper trailer for Tesla fans who can’t just wait for the truck!',
  'Designify Agency Landing Page Design',
  '✨What is Done is Done ✨',
  'Fresh Prince',
  'Six Socks Studio',
  'vincenzo de cotiis’ crossing over showcases a research on contamination',
];

const posts = [...Array(7)].map((_, index) => ({
  id: faker.datatype.uuid(),
  title: POST_TITLES[index],
  icon: 'bell',
  url: 'https://mui.com/material-ui/react-grid/https://mui.com/material-ui/react-grid/',
  lastUpdatedAt: faker.date.past(),
}));

export default posts;
