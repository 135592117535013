import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';

// react-i18n
import { useTranslation } from 'react-i18next';

import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ResourceCard from '../../sections/@dashboard/resources/ResourceCard';
import ResourcesSort from '../../sections/@dashboard/resources/ResourcesSort';
import ResourcesSearch from '../../sections/@dashboard/resources/ResourcesSearch';
import SearchNotFound from '../../components/SearchNotFound';
import tracker from '../../api/tracker';

// ----------------------------------------------------------------------
import RESOURCES from '../../_mock/resources';
import SearchModel from '../../components/SearchModel';
import SortDropdown from '../../components/SortDropdown';
import useQuery from '../../hooks/useQuery';

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'oldest', label: 'Oldest' },
];

export default function ResourcesPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [resourcesList, setResourcesList] = useState([]);
  const [noResourcesFound, setNoResourcesFound] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('desc');

  const fetchData = useQuery();

  const fetchResources = async () => {
    setIsLoading(true);

    // let url = `users?page=${page + 1}`;
    // if (filter) url += `&search=${filter}`;
    const response = await tracker.get('resources');

    // const response = { data: RESOURCES };
    setResourcesList(response.data.data);
    setNoResourcesFound(!response.data.data.length);
    setIsLoading(false);
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      setIsLoading(true);
      await fetchData('resources', search, sort, setResourcesList, 'created_at');
      setIsLoading(false);
    }, 500 * 1);

    return () => {
      clearTimeout(timer);
    };
  }, [sort, search]);

  return (
    <Page title={`Dashboard: ${t('Resources')}`}>
      <Container>
        {/* TODO: Make sure this is responsive */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Resources')}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/resources/new"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {t('New_resource')}
          </Button>
        </Stack>

        <>
          <SearchModel filterName={search} onFilterName={setSearch} placeHolder={t('Search_events')} />
          <SortDropdown sort={sort} setSort={setSort} />

          <Grid style={{ marginBottom: '43px' }} container spacing={3} justifyContent="start">
            {isLoading ? (
              <FadeLoader
                color={'blue'}
                loading={isLoading}
                cssOverride={{
                  margin: '0 auto',
                }}
                size={50}
              />
            ) : (
              resourcesList.map((resource, index) => (
                <ResourceCard key={resource.hashid} resource={resource} index={index} />
              ))
            )}
            {noResourcesFound && <SearchNotFound />}
          </Grid>
        </>
      </Container>
    </Page>
  );
}
