import React, { useEffect, useState } from 'react';
import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { ConfirmationDialog } from '../../components/general/ConfirmationDialog';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from '../../hooks/useQuery';
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from '../../hooks/useApi';

const override = {
  margin: '0 auto',
};

export default function AttendanceIndex() {
  const [attendances, setAttendances] = useState([]);
  const [selectedAttendance, setSelectedAttendance] = useState();
  const [pagination, setPagination] = useState({ total: 0, per_page: 0, current_page: 1, last_page: 1 });
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('desc');
  const [sortField, setSortField] = useState('created_at');
  const [selected, setSelected] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { doDelete } = useApi();
  const [loading, setLoading] = useState(true);

  const fetchData = useQuery();
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'date', label: t('date'), alignRight: false },
    { id: 'attendance', label: t('attendances'), alignRight: false },
    { id: 'info', label: t('info'), sortable: false, alignRight: false },
  ];

  const refreshTable = async () => {
    setIsLoading(true);
    await fetchData('attendance', filter, sort, setAttendances, sortField, setPagination, page, setNotFound);
    setIsLoading(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(refreshTable, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [filter, sort, page, sortField]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = attendances.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteDialog = (attendance) => {
    setSelectedAttendance(attendance);
    setShowDeleteDialog(true);
  };
  const handleDelete = async () => {
    setLoading(true);
    doDelete(`attendance/${selectedAttendance.hashid}`, t('delete_success'), t('delete_error'), refreshTable);
    setLoading(false);
    setShowDeleteDialog(false);
    setSelected([]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilter(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - attendances.length) : 0;

  return (
    <Page title={t('Attendances')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Attendances')}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/attendance/save"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {t('new_attendance')}
          </Button>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filter}
            onFilterName={handleFilterByName}
            placeholder={`search_attendances`}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {isLoading ? (
                  <FadeLoader color={'blue'} loading={loading} cssOverride={override} size={50} />
                ) : (
                  <>
                    <ListHead
                      headLabel={TABLE_HEAD}
                      rowCount={attendances.length}
                      order={sort}
                      omitCheckbox
                      orderBy={sortField}
                      numSelected={selected.length}
                      onRequestSort={setSortField}
                      setSort={setSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {attendances.map((row) => {
                        const { attendance, date, info, hashid } = row;
                        const isItemSelected = selected.indexOf(hashid) !== -1;
                        const formattedDate = format(new Date(date), "EEEE d 'de' MMMM 'del' yyyy h:mm:ss a", {
                          locale: es,
                        });

                        return (
                          <TableRow
                            hover
                            key={hashid}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left">{formattedDate}</TableCell>
                            <TableCell align="left">{attendance}</TableCell>
                            <TableCell align="left">{info}</TableCell>
                            <TableCell align="right">
                              <MoreMenu
                                url={`/dashboard/attendance/edit/${hashid}`}
                                onDelete={() => handleDeleteDialog(row)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    {notFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filter} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={pagination.total}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <ConfirmationDialog
            open={showDeleteDialog}
            onClose={() => {
              setShowDeleteDialog(false);
            }}
            onConfirm={handleDelete}
            message={t('delete_confirm')}
          />
        </Card>
      </Container>
    </Page>
  );
}
