import { faker } from '@faker-js/faker';
import{useState, useEffect} from 'react'
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// components
import Page from '../components/Page';
import BarChart from '../components/chart/BarChart';
import PieChart from '../components/chart/PieChart';
import SideBarChart from '../components/chart/SideBarChart';

import Iconify from '../components/Iconify';
// sections
import {
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import DailyBarChart from '../components/chart/DailyBarChart';


// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          {t('statistics')}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={6} md={6} lg={6}>
            <DailyBarChart
              dataEndpoint="dashboard/attendance/daily"
              title={t('attendance_daily')}
              subheader={t('attendance_daily_description')}
              barDescription={t('attendance')}/>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
           <BarChart
              dataEndpoint="dashboard/attendance/monthly"
              title={t('attendance_monthly')}
              subheader={t('attendance_monthly_description')}
              barDescription={t('attendance')}/>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <BarChart
              dataEndpoint="dashboard/registrations/monthly"
              title={t('registrations_monthly')}
              subheader={t('registrations_monthly_description')}
              barDescription={t('registrations')}/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
