import { useTranslation } from 'react-i18next';
import tracker from '../api/tracker';

export default function useQuery() {
  const { t, i18n } = useTranslation();
  const fetchData = async (
    model,
    search,
    sort,
    setData,
    sortField = 'created_at',
    setPagination = false,
    page = 1,
    setNotFound = false,
    filterScope = ''
  ) => {
    let data;
    console.log('filterscope', filterScope);
    console.log('sortfield', sortField);

    if (search === '') {
      data = await tracker.get(`${model}?sort=${sortField}:${sort}&page=${page}&filter=${filterScope}`);

      setData(data.data.data);
    } else {
      data = await tracker.get(
        `${model}?search=${search}&sort=${sortField}:${sort}&page=${page}&filter=${filterScope}`
      );
      setData(data.data.data);
    }
    if (!data.data.data.length && setNotFound) setNotFound(t('no_records_fund'));
    else if (setNotFound) setNotFound(false);
    if (setPagination) setPagination(data.data.meta);
  };
  return fetchData;
}
