import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';

// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Stack,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css';
// components
import Label from '../../../components/Label';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from '../../../components/hook-form/RHFSelect';
import tracker from '../../../api/tracker';
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import iconOptions from '../../../data/icofont-icons';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

ResourceForm.propTypes = {
  resourceData: PropTypes.object,
};

ResourceForm.defaultValues = {
  resourceData: Object(),
};

export default function ResourceForm({ resourceData }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [hasError, setHasError] = useState(false);

  const defaultValues = {
    name: '',
    url: '',
    icon_photo: '',
  };
  const resourceSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    url: Yup.string().required('Required'),
  });
  const methods = useForm({
    resolver: yupResolver(resourceSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (resourceData) {
      const fields = ['name', 'hashid', 'url'];
      fields.forEach((field) => setValue(field, resourceData[field]));
    }
  }, [resourceData, setValue]);

  const onSubmit = (data) => {
    console.log(data.icon_photo[0]);
    if (data.icon_photo[0]) {
      data.icon_photo = data.icon_photo[0];
    }
    setIsLoading(true);
    console.log(resourceData);
    const formSubmission = resourceData
      ? tracker.put(`resources/${resourceData.hashid}`, data)
      : tracker.post('resources', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

    formSubmission
      .then((result) => {
        setAlertVisible(false);
        navigate('/dashboard/resources');
      })
      .catch((err) => {
        setIsLoading(false);

        setAlertVisible(true);
        setHasError(true);
        console.error(err);
      });
  };

  const onDelete = async () => {
    try {
      const deleteResponse = await tracker.delete(`resources/${resourceData.hashid}`);

      if (deleteResponse.status === 'OK') {
        setHasError(false);
        navigate('/dashboard/resources');
      } else {
        throw Error('Response was not ok');
      }
    } catch (err) {
      setHasError(true);
      setAlertVisible(true);
      setIsDeleting(false);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Collapse in={isAlertVisible}>
        <Alert
          severity={hasError ? 'error' : 'success'}
          sx={{ mb: 5 }}
          onClose={() => {
            setAlertVisible(false);
          }}
        >
          <AlertTitle>{hasError ? t('Something_went_wrong') : t('Success')}</AlertTitle>
        </Alert>
      </Collapse>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}>
          <RHFTextField name="name" label={t('Resource_name')} disabled={isLoading} />
          <RHFTextField name="url" label={t('UResource_RL')} disabled={isLoading} />
          <div style={{ marginTop: '80px' }}>
            <Label>{t('image')}</Label>
            <RHFFileInput name="icon_photo" />
          </div>
          <input type="hidden" name="hashid" />
        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading || isDeleting}>
          {t('Save')}
        </LoadingButton>
        {!!resourceData && (
          <Stack direction="row" alignItems="center" justifyContent="space-around" mt={5}>
            <Button
              variant="contained"
              color="error"
              startIcon={<Iconify icon="quill:folder-trash" />}
              onClick={() => {
                setIsDeleting(true);
              }}
              disabled={isLoading || isDeleting}
            >
              {t('Remove')}
            </Button>
            <Dialog open={isDeleting}>
              <DialogTitle>{t('Confirmation')}</DialogTitle>
              <DialogContent>
                <DialogContentText>{t('Resource_delete_confirmation')}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setIsDeleting(false);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={onDelete}>{t('Remove')}</Button>
              </DialogActions>
            </Dialog>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
}
