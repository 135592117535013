import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink} from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import {useTranslation} from "react-i18next";
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import tracker from "../../../api/tracker";



// ----------------------------------------------------------------------

export default function ResetPasswordForm(token) {

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const ResetSchema = Yup.object().shape({
    email: Yup.string().email(t("Enter_a_valid_email")).required(t("Email_is_required")),
    password: Yup.string().required(t('required')),
    password_confirmation: Yup.string().required(t('required'))
     .oneOf([Yup.ref('password'), null], t('password_must_match'))
  });

  const defaultValues = {
    email: '',
    password: '',
    password_confirmation: '',
  };

  const methods = useForm({
    resolver: yupResolver(ResetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
      setSuccessMessage(null)
      setErrorMessage(null)
      setIsLoading(true)
      const url = `reset_password`;
      data.token = token
      const result = await tracker.post(url, data)
        .catch( (err) => {
            if(err.response.status === 422)
              setErrorMessage(err.response.data.errors.email)
            else
              setErrorMessage(t("something_went_wrong"))  
        })
      if(result.status === 200) {
        setSuccessMessage(t("reset_success"))
      }
        
      setIsLoading(false)
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
      <RHFTextField className="mb-5" name="email" label={t("Correo_Electronico")} />
      <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="password_confirmation"
          label="Password confirmation"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errorMessage && (
          <Alert severity="error"> {t(errorMessage)}</Alert>
        )}
        {successMessage && (
          <Alert severity="success"> {t(successMessage)}</Alert>
        )}
      </Stack>

    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        {t('Forgot_Password')}
      </LoadingButton>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/login">
          {t('back_to_login')}
        </Link>
      </Stack>
    </FormProvider>

    
  );
}
