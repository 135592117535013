import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { Stack, Link, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css';
import { paymentTypes } from '../../../constants';
// components

import Label from '../../../components/Label';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import RHFSelect from '../../../components/hook-form/RHFSelect';
import { useApi } from '../../../hooks/useApi';
import tracker from '../../../api/tracker';
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import { PinDialog } from '../../../components/general/PinDialog';

// ----------------------------------------------------------------------

export default function AnnouncementForm({ announcement }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { doSave } = useApi();
  const [roles, setRoles] = useState([]);
  const [showPinDialog, setShowPinDialog] = useState(false);
  const [pinDialogMessage, setPinDialogMessage] = useState(false);
  const defaultValue = new Date('2022-04-17T15:30');
  const [datePickerEl, setDatePickerAnchorElement] = useState(null);
  const [massDate, setMassDate] = useState(null);
  const [productPhotos, setProductPhotos] = useState([]);

  const fetchRoles = async () => {
    const result = await tracker.get('roles');
    setRoles(result.data.data);
  };

  useEffect(() => {
    if (announcement) {
      const fields = ['title', 'body', 'active'];
      fields.forEach((field) => setValue(field, announcement[field]));
    }
    fetchRoles();
    // console.log(bulletin);
  }, [announcement]);

  const ModelSchema = Yup.object().shape({
    title: Yup.string().required(t('Required')),
  });

  const defaultValues = {
    title: '',
    active: false,
  };

  const methods = useForm({
    resolver: yupResolver(ModelSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
  } = methods;

  const onSubmit = async (data) => {
    if (data.image) {
      const file = data.image[0];
      console.log(`Original file size: ${(file.size / 1024 / 1024).toFixed(2)} MB`);
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      setValue('image', compressedFile);
    } else data.image = null;

    // console.log(data);
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await doSave('announcements', data, t('announcement_saved'), '', announcement, headers);
    navigate(`/dashboard/announcement/`);
    if (response.status === 423) {
      setPinDialogMessage(response.response.data.message);
      setShowPinDialog(true);
    }
  };

  const handleDateTimeChange = (newValue) => {
    setMassDate(newValue);
    // setValue('date_start', sd);
    console.log(massDate);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1} direction={'column'}>
          <RHFTextField name="title" label={t('title')} />

          <RHFTextField name="body" label={t('description')} />
          <div style={{ marginTop: '80px' }}>
            <Label>{t('image')}</Label>
            <RHFFileInput name="image" />
          </div>
          <RHFCheckbox name="active" label="Active" />
        </Stack>
        <input type="hidden" name="id" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/dashboard/announcement/">
          {t('go_back')}
        </Link>
      </Stack>
    </FormProvider>
  );
}
