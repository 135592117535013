import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import Compress from 'compress.js';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui

import { Stack, Link, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css';
import { paymentTypes } from '../../../constants';
// components

import Label from '../../../components/Label';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from '../../../components/hook-form/RHFSelect';
import { useApi } from '../../../hooks/useApi';
import tracker from '../../../api/tracker';
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import { PinDialog } from '../../../components/general/PinDialog';

// ----------------------------------------------------------------------

export default function CarrouselForm({ carrousel }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { doSave } = useApi();
  const [roles, setRoles] = useState([]);
  const [showPinDialog, setShowPinDialog] = useState(false);
  const [pinDialogMessage, setPinDialogMessage] = useState(false);
  const defaultValue = new Date('2022-04-17T15:30');
  const [datePickerEl, setDatePickerAnchorElement] = useState(null);
  const [massDate, setMassDate] = useState(null);
  const [productPhotos, setProductPhotos] = useState([]);
  const [compressedImageSrc, setCompressedImageSrc] = useState('');

  const fetchRoles = async () => {
    const result = await tracker.get('roles');
    setRoles(result.data.data);
  };

  useEffect(() => {
    if (carrousel) {
      const fields = ['title', 'body'];
      fields.forEach((field) => setValue(field, carrousel[field]));
    }
    fetchRoles();
    // console.log(bulletin);
  }, [carrousel]);

  const ModelSchema = Yup.object().shape({
    title: Yup.string().required(t('Required')),
  });

  const defaultValues = {
    title: '',
  };

  const methods = useForm({
    resolver: yupResolver(ModelSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
  } = methods;
  const base64ToBlob = (base64, type) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      // Cambiado ++ a += 1
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
  };

  const onSubmit = async (data) => {
    console.log('hole');
    const formData = new FormData();
    if (!data.image) {
      data.image = null;
    }
    if (data.image) {
      const file = data.image[0];
      console.log(`Original file size: ${(file.size / 1024 / 1024).toFixed(2)} MB`);
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };

      // Imprimir el tamaño original de la imagen
      // console.log(`Original file size: ${(file.size / 1024 / 1024).toFixed(2)} MB`);

      const compressedFile = await imageCompression(file, options);

      formData.append('image', compressedFile);
    }

    formData.append('title', data.title);
    formData.append('body', data.body);

    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const response = await doSave('carrousels', formData, t('carrousel_saved'), '', carrousel, headers);
    navigate(`/dashboard/carrousel/`);
    if (response.status === 423) {
      setPinDialogMessage(response.response.data.message);
      setShowPinDialog(true);
    }
  };

  const handleDateTimeChange = (newValue) => {
    setMassDate(newValue);
    // setValue('date_start', sd);
    console.log(massDate);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1} direction={'column'}>
          <RHFTextField name="title" label={t('title')} />

          <RHFTextField name="body" label={t('description')} />
          <div style={{ marginTop: '80px' }}>
            <Label>{t('image')}</Label>
            <RHFFileInput name="image" />
          </div>
        </Stack>
        <input type="hidden" name="id" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/dashboard/carrousel">
          {t('go_back')}
        </Link>
      </Stack>
    </FormProvider>
  );
}
