import {React} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';

export function ConfirmationDialog(props) {
  const { onClose,onConfirm, message, open, loading, forDelete, ...other } = props;
  const {t} = useTranslation();

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >

      <DialogTitle>{t('confirmation')}</DialogTitle>
      <DialogContent dividers>
        <Alert severity={forDelete ? 'error': 'info'}> {t(message)}</Alert>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {t('cancel')}
        </Button>
        {forDelete ? (
          <LoadingButton loading={loading} color="error" variant="contained" onClick={onConfirm}>{t('delete')}</LoadingButton>
        ) : (
          <LoadingButton loading={loading} variant="contained" onClick={onConfirm}>{t('confirm')}</LoadingButton>
        )}

      </DialogActions>
    </Dialog>
  );
}