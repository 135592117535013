
import{useState, useEffect} from 'react'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import tracker from "../../api/tracker";
import {
  AppWebsiteVisits
} from '../../sections/@dashboard/app';
// sections


// ----------------------------------------------------------------------

export default function BarChart({
  title,
  subheader,
  dataEndpoint,
  barDescription
}) {

  const {t} = useTranslation();
  const [monthlyRevenue, setMonthlyRevenue] = useState({
    months: [],
    data: []
  });
  const [monthlyRevenueYear, setMonthlyRevenueYear] = useState(moment().year());
  const[yearList, setYearList] = useState([]);

  const setYear = (event, value) => {

      setMonthlyRevenueYear(value.props.value)
  }


  const fetchMontlyRevenue = async () => {
    const result = await tracker.get(`${dataEndpoint}?year=${monthlyRevenueYear}`)
    const revenueInfo = {
      months: result.data.months,
      data: [
        {
          data: result.data.data,
          name: barDescription,
          type: 'column',
          fill: 'solid'
        }
      ]
    }
    setYearList(result.data.years)
    setMonthlyRevenue(revenueInfo)
  }
  useEffect(() => {
    fetchMontlyRevenue()
  }, [monthlyRevenueYear])
  console.log(monthlyRevenueYear)
  return (
        <>
            <AppWebsiteVisits
              title={title}
              subheader={subheader}
              chartLabels={monthlyRevenue.months}
              chartData={monthlyRevenue.data}
              yearList={yearList}
              selectedYear={monthlyRevenueYear}
              setYear={setYear}
            />
        </>
  );
}
