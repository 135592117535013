import PropTypes from 'prop-types';

// @mui
import { FormControl, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import Icofont from 'react-icofont';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

FreeSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  hasIcons: PropTypes.bool,
};

export default function FreeSelect({ name, hasIcons, options, setValue, initialValue,value, ...other }) {
  const {t} = useTranslation();
  const handleChange = (event) => {
    setValue(event.target.value)
  }
  console.log(initialValue)
  return (
        <FormControl>
        <InputLabel id="demo-simple-select-label">{other.label}</InputLabel>
        <Select

          fullWidth
          onChange={handleChange}
          defaultValue={initialValue}
          value={value}
          {...other}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              
              {hasIcons && <Icofont icon={option.value} size={2} sx={{ ml: 20 }} />}&nbsp;&nbsp;&nbsp;&nbsp;
              {t(option.label)}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
  );
}
