import React from 'react';

import { useTranslation } from 'react-i18next';

const ChildrenDetails = ({ children }) => {
  const { t } = useTranslation();

  if (!children || !Array.isArray(children)) {
    return <div>{t('No_children_data_available')}</div>;
  }

  return (
    <div>
      <h3>{t('Children_Details')}</h3>
      <ul>
        {children.map((child, index) => (
          <li key={index}>
            <strong>{t('Name')}:</strong> {child.name}, <strong>{t('Age')}:</strong> {child.age}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChildrenDetails;
