import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ConstructionOutlined, Title } from '@mui/icons-material';
import { color } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  Box,
  Divider,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import MyAccordion from '../../components/MyAccordion';
import { ConfirmationDialog } from '../../components/general/ConfirmationDialog';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from '../../hooks/useQuery';
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from '../../hooks/useApi';
import FreeSelect from '../../components/hook-form/FreeSelect';
import ParentDetailsView from './ParentDetailsView';
import InterestDetailsView from './InterestDetailsView';
import FamilyMembers from './FamilyMembers';
import tracker from '../../api/tracker';

const override = {
  margin: '0 auto',
};

export default function RegistrationView() {
  const [registrationViews, setRegistrationViews] = useState([]);
  const { hashid } = useParams();
  const [selectedRegistrationView, setSelectedRegistrationView] = useState();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('asc');
  const [sortField, setSortField] = useState('created_at');
  const [selected, setSelected] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { doDelete } = useApi();
  const [loading, setLoading] = useState(true);

  const [userInterests, setUserInterests] = useState();
  const fetchData = useQuery();
  const { t } = useTranslation();
  const [familyName, setFamilyName] = useState();
  const [husbandName, setHusbandName] = useState();
  const [wifeName, setWifeName] = useState();
  const [phone, setPhone] = useState();
  const [language, setLanguage] = useState();
  const [children, setChildren] = useState();
  const [familyMembers, setFamilyMembers] = useState();
  const [interests, setInterests] = useState();

  const [status, setStatus] = useState(' ');
  const [address, setAddress] = useState('');
  const [trackingText, setTrackingText] = useState(' ');
  const [formattedCreatedAt, setFormattedCreatedAt] = useState();
  const [registrationView, setRegistrationView] = useState();

  const fetchRegistrationViewData = async () => {
    setLoading(true);
    const response = await tracker.get(`registrations/${hashid}`);
    const registration = response.data.data;
    console.log(response.data.data);
    setRegistrationView(response.data.data);
    setUserInterests(response.data.data.interests);
    setFamilyName(response.data.data.family_name);
    setHusbandName(response.data.data.husband_name);
    setWifeName(response.data.data.wife_name);
    setPhone(response.data.data.phone);
    setChildren(response.data.data.children);
    setLanguage(response.data.data.language);
    setStatus(response.data.data.status);
    setInterests(response.data.data.interests);
    setFamilyMembers(response.data.data.family_members);
    console.log(children);
    const address = {
      line_1: registration.address_line_1,
      line_2: registration.address_line_2,
      city: registration.city,
      state: registration.state,
      zip: registration.zip,
    };
    setAddress(address);
    setLoading(false);
  };

  useEffect(() => {
    fetchRegistrationViewData();
  }, [hashid]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = registrationView.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteDialog = (registrationView) => {
    setSelectedRegistrationView(registrationView);
    setShowDeleteDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilter(event.target.value);
  };

  const getStatusColor = (status) => {
    const style = {
      paddingLeft: '10px',
      fontWeight: 'bold',
      paddingBottom: '1px',
      paddingRight: '10px',
      paddingTop: '1px',
      borderRadius: '30px',
    };
    if (status === 'rejected') {
      style.color = '#FF003F';
      style.backgroundColor = '#F8E3E1';
    } else if (status === 'pending') {
      style.color = '#6d8ee9';
      style.backgroundColor = '#e0e9fa';
    } else if (status === 'accepted') {
      style.color = '#6ca181';
      style.backgroundColor = '#dff8e7';
    } else if (status === 'placed') {
      style.color = '#d1b573';
      style.backgroundColor = '#fcf9ce';
    }

    return style;
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - registrationViews.length) : 0;
  console.log(children);
  const wStatusCapitalized = status.charAt(0).toUpperCase() + status.slice(1);
  return (
    <Page title={t('registration_Views')}>
      <Container>
        <Card>
          <Box sx={{ p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
            {loading ? (
              <FadeLoader color={'blue'} loading={loading} cssOverride={override} size={50} />
            ) : (
              <>
                <Typography variant="h6" gutterBottom>
                  {t('User_Information')} <span style={getStatusColor(status)}>{t(status)}</span>
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle1">
                    <strong>{t('Family_Name')}:</strong> {familyName} {/* Traduce y muestra "Family Name" */}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>{t('Husband_Name')}:</strong> {husbandName} {/* Traduce y muestra "Husband Name" */}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>{t('Wife_Name')}:</strong> {wifeName} {/* Traduce y muestra "Wife Name" */}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>{t('Address')}:</strong> {address.line_1}
                    {address.line_2 && `, ${address.line_2}`}
                    {`, ${address.city}, ${address.state} ${address.zip}`}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>{t('Phone')}:</strong> {phone} {/* Traduce y muestra "Phone" */}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>{t('Language')}:</strong> {language} {/* Traduce y muestra "Language" */}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>{t('Status')}:</strong> {status} {/* Traduce y muestra "Status" */}
                  </Typography>
                </Box>
              </>
            )}
          </Box>

          <ConfirmationDialog
            open={showDeleteDialog}
            onClose={() => {
              setShowDeleteDialog(false);
            }}
            /* onConfirm={handleDelete} */
            message={t('delete_confirm')}
          />
        </Card>

        <Card>
          <Box sx={{ p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
            <ParentDetailsView children={children} />
          </Box>
        </Card>
        <Card>
          <Box sx={{ p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
            <InterestDetailsView interests={interests} />
          </Box>
        </Card>
        <Card>
          <Box sx={{ p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
            <FamilyMembers familyMembers={familyMembers} />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
