import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Link,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from '@mui/material';
// components
import { ConfirmationDialog } from '../../components/general/ConfirmationDialog';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from '../../hooks/useQuery';
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from '../../hooks/useApi';
import tracker from '../../api/tracker';
import FreeSelect from '../../components/hook-form/FreeSelect';
import SortDropdown from '../../components/SortDropdown';
import useNotification from '../../hooks/useNotificaction';
import { PinDialog } from '../../components/general/PinDialog';

const FileDownload = require('js-file-download');

const override = {
  margin: '0 auto',
};

export default function DocRequestIndex() {
  const [docRequests, setDocRequests] = useState([]);
  const [sendNotification] = useNotification();
  const [selectedDocRequest, setSelectedDocRequest] = useState();
  const [pagination, setPagination] = useState({ total: 0, per_page: 0, current_page: 1, last_page: 1 });
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [filterScope, setFilterScope] = useState('all');
  const [showShipDialog, setShowShipDialog] = useState(false);
  const [sort, setSort] = useState('desc');
  const [sortField, setSortField] = useState('created_at');
  const [selected, setSelected] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { doDelete } = useApi();
  const [loading, setLoading] = useState(true);
  const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

  const fetchData = useQuery();
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'created_at', label: t('created_at'), alignRight: false },
    { id: 'requestor_name', label: t('requestor_name'), alignRight: false },

    { id: 'phone', label: t('phone'), alignRight: false },

    { id: 'address_line_1', label: t('full_address'), sortable: false, alignRight: false },
    { id: 'status', label: t('status'), alignRight: false },
    { id: 'type', label: t('certificate'), alignRight: false, sortable: false },

    { id: 'action', label: '', sortable: false, alignRight: false },
  ];

  const filterOptions = [
    { value: 'all', label: t('all') },
    { value: 'forStatus(pending)', label: t('pending') },
    { value: 'forStatus(accepted)', label: t('accepted') },
    { value: 'forStatus(rejected)', label: t('rejected') },
  ];

  const refreshTable = async () => {
    setIsLoading(true);
    await fetchData(
      'docRequests',
      filter,
      sort,
      setDocRequests,
      sortField,
      setPagination,
      page,
      setNotFound,
      filterScope
    );
    setIsLoading(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(refreshTable, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [filter, sort, page, sortField, filterScope]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = docRequests.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilter(event.target.value);
  };
  const getStatusColor = (status) => {
    const style = {
      paddingLeft: '10px',
      fontWeight: 'bold',
      paddingBottom: '1px',
      paddingRight: '10px',
      paddingTop: '1px',
      borderRadius: '30px',
    };
    if (status === 'rejected') {
      style.color = '#FF003F';
      style.backgroundColor = '#F8E3E1';
    } else if (status === 'pending') {
      style.color = '#6d8ee9';
      style.backgroundColor = '#e0e9fa';
    } else if (status === 'accepted') {
      style.color = '#6ca181';
      style.backgroundColor = '#dff8e7';
    } else if (status === 'placed') {
      style.color = '#d1b573';
      style.backgroundColor = '#fcf9ce';
    }

    return style;
  };
  const onStatusChange = async (status, docRequestHash) => {
    const response = await tracker.post(`docRequests/${docRequestHash}/setStatus`, {
      status,
    });
    sendNotification({ msg: t('status_updated'), variant: 'success' });
    refreshTable();
  };
  const onShip = async (tracking) => {
    const response = await tracker.post(`docRequests/${selectedDocRequest.hashid}/update`, {
      tracking_numbers: tracking.split(','),
      status: 'accepted',
    });
    sendNotification({ msg: t('status_updated'), variant: 'success' });
    setShowShipDialog(false);
    refreshTable();
  };

  const downloadReceipts = async (docRequestHash, docRequestNumber) => {
    const url = `docRequests/${docRequestHash}/download`;
    const headers = {
      responseType: 'blob',
    };
    setIsDownloading(true);
    const response = await tracker.get(url, headers);
    setIsDownloading(false);
    setSelectedDocRequest(null);

    FileDownload(response.data, `invoice_${docRequestNumber}.pdf`);
  };

  return (
    <Page title={t('docRequests1')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('docRequests1')}
          </Typography>
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
            <ListToolbar
              numSelected={selected.length}
              filterName={filter}
              onFilterName={handleFilterByName}
              placeholder={t('search_docRequests')}
            />
            <SortDropdown sx={{ width: '200px' }} options={filterOptions} sort={filterScope} setSort={setFilterScope} />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {isLoading ? (
                  <FadeLoader color={'blue'} loading={loading} cssOverride={override} size={50} />
                ) : (
                  <>
                    <ListHead
                      headLabel={TABLE_HEAD}
                      rowCount={docRequests.length}
                      omitCheckbox
                      order={sort}
                      orderBy={sortField}
                      numSelected={selected.length}
                      onRequestSort={setSortField}
                      setSort={setSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {docRequests.map((row) => {
                        const {
                          address_line_1: fullAddress,
                          requestor_name: requestoName,
                          created_at: createdAt,
                          request_reason: requestReason,
                          status,
                          type,
                          phone,
                          hashid,
                        } = row;

                        const statusCapitalized = status.charAt(0).toUpperCase() + status.slice(1);
                        console.log(fullAddress);
                        const isItemSelected = selected.indexOf(hashid) !== -1;
                        const shouldLoading =
                          selectedDocRequest && selectedDocRequest.hashid === hashid && isDownloading;
                        return (
                          <TableRow
                            hover
                            key={hashid}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left">{createdAt}</TableCell>
                            <TableCell align="left">{requestoName}</TableCell>

                            <TableCell align="left">{phone}</TableCell>
                            <TableCell align="left">{fullAddress}</TableCell>
                            <TableCell align="left">
                              <span style={getStatusColor(status)}>{t(status)}</span>
                            </TableCell>
                            <TableCell align="left">{t(type)}</TableCell>
                            <TableCell align="left">
                              <FreeSelect
                                initialValue={status}
                                onChange={(e) => onStatusChange(e.target.value, hashid)}
                                options={[
                                  { value: 'accepted', label: t('accepted') },
                                  { value: 'pending', label: t('pending') },
                                  { value: 'rejected', label: t('rejected') },
                                ]}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <Link
                                component={RouterLink}
                                variant="subtitle2"
                                underline="hover"
                                to={`/dashboard/docRequest/view/${hashid}`}
                              >
                                <IconButton>
                                  <Iconify icon="mdi:magnify-plus-outline" width={30} height={30} />
                                </IconButton>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    {notFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filter} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={pagination.total}
            rowsPerPage={pagination.per_page}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <PinDialog
            open={showShipDialog}
            onClose={() => {
              setShowShipDialog(false);
            }}
            free
            onSubmit={onShip}
            // loading={isSubmitting}
            message={
              'Add tracking numbers separated by commas (,). This will send an email notifying the user who placed the order'
            }
          />
          <ConfirmationDialog
            open={showDeleteDialog}
            onClose={() => {
              setShowDeleteDialog(false);
            }}
            /* onConfirm={handleDelete} */
            message={t('delete_confirm')}
          />
        </Card>
      </Container>
    </Page>
  );
}
